<template>
  <app-list title="Rapoarte stații"
            @actions="dense = $event.dense"
            show-filters
            no-search-padding
            export-query-type="stationsReportExport"
            :search-by="['LocationName', 'SerialNr']"
            default-input-name="filterInput"
            :additional-query-params="params"
            :height="180"
            dense-filters
            :query-params="getParams"
            :query-fields="[
                'StationID',
                'LocationID',
                'LocationName',
                'NewtonStationID',
                'SerialNr',
                'TotalIn',
                'TotalOut',
                'TotalBet',
                'TotalWin',
                'TotalProfit',
                'NrOfGames',
                'TotalProfitAfterReset',
            ]"
            query-type="stationsReport">
    <template #head>
      <lth sort-by="LocationName">Nume locație</lth>
      <lth sort-by="SerialNr">Serial nr</lth>
<!--      <lth sort-by="CabinetName">Cabinet</lth>-->
<!--      <lth sort-by="VendorName">Producător</lth>-->
<!--      <lth sort-by="GameName">Denumire joc</lth>-->
      <lth sort-by="TotalIn">TotalIn</lth>
      <lth sort-by="TotalOut">TotalOut</lth>
      <lth sort-by="TotalBet">TotalBet</lth>
      <lth sort-by="TotalProfit">TotalProfit</lth>
      <lth>TotalProfitAfterReset</lth>
      <lth sort-by="NrOfGames">NrOfPosts</lth>
    </template>
    <template #filters>
      <v-col cols="12" md="3" lg="3" xl="2">
        <app-range-picker :value="[params.customFilterInput.StartDateUtc, params.customFilterInput.EndDateUtc]"
                          :dense="dense" outlined label="Filtreaza după dată" @input="handleDateChange"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf v-model="params.customFilterInput.CabinetID" multiselect independent field="CabinetID" :items="cabinets" label="Cabinet"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf v-model="params.customFilterInput.VendorID" multiselect independent field="VendorID" :items="vendors" label="Producător"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf v-model="params.customFilterInput.GameID" multiselect independent field="GameID" :items="games" label="Denumire joc"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd :link="`locations/edit/${item.LocationID}`">{{ item.LocationName }}</ltd>
      <ltd :link="`stations/edit/${item.StationID}`">{{ item.SerialNr }}</ltd>
<!--      <ltd :link="`cabinets/edit/${item.CabinetID}`">{{ item.CabinetName }}</ltd>-->
<!--      <ltd :link="`vendors/edit/${item.VendorID}`">{{ item.VendorName }}</ltd>-->
<!--      <ltd :link="`games/edit/${item.GameID}`">{{ item.GameName }}</ltd>-->
      <ltd amount>{{ item.TotalIn }}</ltd>
      <ltd amount>{{ item.TotalOut }}</ltd>
      <ltd amount>{{ item.TotalBet }}</ltd>
      <ltd amount>{{ item.TotalProfit }}</ltd>
      <ltd amount>{{ item.TotalProfitAfterReset }}</ltd>
      <ltd amount>{{ item.NrOfGames }}</ltd>
    </template>
    <template #footer="{items}">
      <tr>
        <lth></lth>
        <lth></lth>
<!--        <lth></lth>-->
<!--        <lth></lth>-->
<!--        <lth></lth>-->
        <lth>{{ getTotal('TotalIn', items) }}</lth>
        <lth>{{ getTotal('TotalOut', items) }}</lth>
        <lth>{{ getTotal('TotalBet', items) }}</lth>
        <lth>{{ getTotal('TotalProfit', items) }}</lth>
        <lth>{{ getTotal('TotalProfitAfterReset', items) }}</lth>
        <lth>{{ getTotal('NrOfGames', items) }}</lth>
      </tr>
    </template>
  </app-list>
</template>
<script>
export default {
  data() {
    let date1 = this.$moment().format('YYYY-MM-DD');
    let date2 = this.$moment().format('YYYY-MM-DD');
    if (this.$route.query.date1) {
      date1 = this.$route.query.date1;
      date2 = this.$route.query.date2;
    }

    return {
      dense: false,

      vendors: [],
      cabinets: [],
      games: [],

      params: {
        customFilterInput: {
          GameID: [],
          VendorID: [],
          CabinetID: [],
          LocationID: this.$route.query.locationId ? [this.$route.query.locationId] : [],
          StartDateUtc: date1,
          EndDateUtc: date2,
        }
      }
    }
  },

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('cabinets', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.cabinets = res.data.cabinets.Items;
    });

    this.getGQLItems('games', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.games = res.data.games.Items;
    });

    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.vendors = res.data.vendors.Items;
    });
  },

  methods: {
    handleDateChange: function (val) {
      this.params.customFilterInput.StartDateUtc = val[0];
      this.params.customFilterInput.EndDateUtc = val[1];
    },
  },

  computed: {
    getParams: function() {
      if (this.getRole === 'manager') {
        return `{
              Eq: {
                Name: "LocationID"
                Value: "${this.getUser.LocationID}"
              }
            }`;
      }
      return ``;
    }
  }
}
</script>