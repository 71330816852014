var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list',{attrs:{"title":"Tip cheltuieli","search-by":['Name'],"show-filters":"","query-fields":[
              'ID',
              'Name',
              'DepartmentID',
              'Department {Name}',
              'CreatedAt'
          ],"delete-mutation-type":"deleteExpenditureType","delete-mutation-field":"ids","delete-by":_vm.getRole !== 'operations' ? 'ID' : null,"query-type":"expenditureTypes","add-link":"expenditures/add"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name"}},[_vm._v("Nume")]),_c('lth',[_vm._v("Departament")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Data creării")])]},proxy:true},{key:"filters",fn:function(){return [_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3","xl":"2"}},[_c('lf',{attrs:{"field":"DepartmentID","multiselect":"","items":_vm.departments,"label":"Departament"}})],1)]},proxy:true},{key:"row",fn:function({item}){return [_c('ltd',{attrs:{"link":`expenditures/edit/${item.ID}`}},[_vm._v(_vm._s(item.Name))]),_c('ltd',{attrs:{"link":`departments/edit/${item.DepartmentID}`}},[_vm._v(_vm._s(item.Department ? item.Department.Name : ''))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }