var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list',{attrs:{"title":"Furnizori","search-by":['Name', 'FiscalCode', 'Address', 'Phone'],"show-filters":"","large-search":"","query-fields":[
              'ID',
              'Name',
              'FiscalCode',
              'Address',
              'Phone',
              'CreatedAt'
          ],"delete-mutation-type":"deleteInventoryVendor","delete-mutation-field":"ids","export-query-type":"inventoryVendorsExport","delete-by":_vm.getRole !== 'operations' ? 'ID' : null,"query-type":"inventoryVendors","add-link":"providers/add"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name"}},[_vm._v("Denumire")]),_c('lth',{attrs:{"sort-by":"FiscalCode"}},[_vm._v("FiscalCode")]),_c('lth',{attrs:{"sort-by":"Address"}},[_vm._v("Address")]),_c('lth',{attrs:{"width":"200","sort-by":"Phone"}},[_vm._v("Phone")]),_c('lth',{attrs:{"width":"120","sort-by":"CreatedAt"}},[_vm._v("Data creării")])]},proxy:true},{key:"row",fn:function({item}){return [_c('ltd',{attrs:{"link":`inventory/providers/edit/${item.ID}`}},[_vm._v(_vm._s(item.Name))]),_c('ltd',[_vm._v(_vm._s(item.FiscalCode))]),_c('ltd',[_vm._v(_vm._s(item.Address))]),_c('ltd',[_vm._v(_vm._s(item.Phone))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }