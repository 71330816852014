<template>
  <app-list title="Tranzacții"
            v-if="operationalDate"
            :query-fields="[
                'ID',
                'Date',
                'Explanation',
                'Amount',
                'Direction',
                'DocumentNrTranslated',
                'ExplanationTranslated',
                'TypeID',
                'VendorID',
                'OtherInfo {DocumentNr}',
                'Department {Name}',
                'Location {Name}',
                'Attachments {ID}',
                'Type {Name}',
                'Vendor {Name}',
                'CreatedAt',
            ]"
            no-start-request
            :query-params="params"
            @actions="actions = $event"
            :delete-by="getRole !== 'operational' ? 'ID' : null"
            delete-mutation-type="deletePayment"
            export-query-type="paymentsExport"
            delete-mutation-field="ids"
            :height="210"
            query-type="payments">
    <template #actions>
      <v-btn outlined text color="primary" exact :to="toPage(`transactions/cash/add`)" class="ml-1 v-btn--active">
        <v-icon>add</v-icon>
        Adaugă incasare
      </v-btn>

      <v-btn outlined text color="success" exact :to="toPage(`transactions/payment/add`)" class="ml-1 v-btn--active">
        <v-icon>add</v-icon>
        Adaugă plată
      </v-btn>
    </template>
    <template #filters>
      <v-col v-if="getRole !== 'manager'" cols="12" md="4" lg="3" xl="2">
        <lf :clearable="false" field="LocationID" multiselect :items="locations" label="Locație"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf :clearable="false" @input="handleOperationalDate" date field="OperationalDate" label="Data operațională"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf field="TypeID" multiselect :items="paymentTypes" label="Tip"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf multiselect field="VendorID" :items="inventoryVendors" label="Furnizor"/>
      </v-col>
    </template>
    <template #head>
      <lth width="90" sort-by="Date">Nr Crt.</lth>
      <lth v-if="getRole !== 'manager'">Locație</lth>
      <lth width="120" sort-by="Date">Data Înreg.</lth>
      <lth width="50"></lth>
      <lth>Document Nr</lth>
      <lth sort-by="TypeID">Explicația</lth>
      <lth>Detalii</lth>
      <lth sort-by="Amount">Incasat</lth>
      <lth sort-by="Amount">Plați</lth>
    </template>
    <template #row="{item, index}">
      <ltd>{{ index + 1 }}</ltd>
      <ltd v-if="getRole !== 'manager'">{{ item.Location ? item.Location.Name : '' }}</ltd>
      <ltd date>{{ item.Date }}</ltd>
      <ltd>
        <template v-if="item.Attachments.length > 0">
          <v-icon>attach_file</v-icon>
        </template>
      </ltd>
      <ltd>{{ item.DocumentNrTranslated }}</ltd>
      <ltd :link="`transactions/edit/${!toBoolean(item.Direction) ? 'cash' : 'payment'}/${item.ID}`">
        {{ item.ExplanationTranslated }}
      </ltd>
      <ltd>{{ item.Explanation }}</ltd>
      <ltd amount>{{ !toBoolean(item.Direction) ? item.Amount : 0 }}</ltd>
      <ltd amount>{{ toBoolean(item.Direction) ? item.Amount : 0 }}</ltd>
    </template>
    <template #footer="{items}">
      <tr>
        <lth v-if="actions.selectMode"></lth>
        <lth v-if="getRole !== 'manager'"></lth>
        <lth>Total</lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth>{{ getTotal('Amount', getItems(items, false)) }}</lth>
        <lth>{{ getTotal('Amount', getItems(items, true)) }}</lth>
      </tr>
    </template>
  </app-list>
</template>
<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      paymentTypes: [],
      actions: {},
      operationalDate: null,
      inventoryVendors: [],
      locations: [],
    }
  },

  created() {
    this.$store.commit('setListSort', {
      direction: 'desc',
      field: 'CreatedAt'
    });

    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });


    this.getGQLItems('inventoryVendors', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.inventoryVendors = res.data.inventoryVendors.Items;
    });


    let filters = [];
    if (this.$route.query.filters) {
      filters = JSON.parse(this.$route.query.filters);
    }
    let existentFilter = filters.find(f => f.field === 'OperationalDate');
    if (!existentFilter) {
      let localOperationalDate = localStorage.getItem('app.list.transactions.filters.operational_date');

      if (localOperationalDate) {
        localOperationalDate = localOperationalDate.split(',');
        this.operationalDate = localOperationalDate;
        this.filterOperationalDate(false);
      } else {
        if (this.getRole === 'manager') {
          let yesterday = this.$moment().subtract('1', 'days').format('YYYY-MM-DD');
          console.log('yesterday', yesterday);
          this.operationalDate = [yesterday, yesterday];
          this.filterOperationalDate(false);
        } else {
          this.$apollo.query({
            query: gql`query {
          currentOperationalDate
      }`
          }).then(res => {
            this.operationalDate = res.data.currentOperationalDate;
            this.filterOperationalDate();
          });
        }
      }


    } else {
      this.operationalDate = existentFilter.value[0];
    }
  },

  mounted() {

    this.getGQLItems('paymentTypes', [
      'Name',
      'ID',
    ]).then(res => {
      this.paymentTypes = res.data.paymentTypes.Items;
    });
  },

  methods: {
    handleOperationalDate: function (value) {
      localStorage.setItem('app.list.transactions.filters.operational_date', value);
    },

    filterOperationalDate: function (singleValue = true) {
      let value = null;
      if (singleValue) {
        value = [this.operationalDate, this.operationalDate];
      } else {
        value = this.operationalDate;
      }

      this.$nextTick(() => {
        this.$store.commit('filterListBy', {
          field: 'OperationalDate',
          value: value,
          type: 'date',
          filterInputName: 'default'
        });
      });
    },

    getItems: function (items, direction = false) {
      let filtered = [];
      items.forEach(i => {
        if (i.Direction == direction) {
          filtered.push(i);
        }
      });

      return filtered;
    }
  },

  computed: {
    params: function () {
      if (this.getRole !== 'manager') {
        return ``;
      }
      return `{
            Eq: {
              Name: "LocationID"
              Value: "${this.getUser.LocationID}"
            }
          }`;
    },
  },

}
</script>