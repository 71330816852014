<template>
  <app-list title="Producători"
            :search-by="['Name']"
            show-filters
            :query-fields="[
                'ID',
                'Name',
                'NrOfStations',
                'CreatedAt'
            ]"
            delete-mutation-type="deleteVendor"
            delete-mutation-field="vendorIDs"
            export-query-type="vendorsExport"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="vendors"
            add-link="vendors/add"
            hide-filters>
    <template #head>
      <lth sort-by="Name">Nume</lth>
      <lth sort-by="NrOfStations">NrOfStations</lth>
      <lth sort-by="CreatedAt">Data creării</lth>
    </template>
    <template #row="{item}">
      <ltd :link="`vendors/edit/${item.ID}`">{{ item.Name }}</ltd>
      <ltd>{{ item.NrOfStations }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  created() {
    this.$store.commit('setListSort', {
      direction: 'asc',
      field: 'Name'
    });
  }
}
</script>