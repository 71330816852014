<template>
  <div :class="{'app-form-section-block': true, 'no-margin': noMargin}">
    <div v-if="icon" class="app-form-section-block-icon">
      <v-icon color="quaternary" size="20">{{ icon }}</v-icon>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    icon: String,
    noMargin: Boolean,
  },

  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>

.app-form-section-block {
  border-bottom: 1px dashed var(--light-border-color);
  padding: 42px 32px;
  position: relative;

  &:nth-child(1) {
    padding-top: 5px !important;
  }

  @media screen and (max-width: 600px) {
    padding: 20px 15px;
  }

  &.no-margin {
    padding: 0;
  }

  &-icon {
    @media screen and (max-width: 600px) {
      display: none;
    }

    width: 56px;
    height: 56px;
    background: #FFFFFF;
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    position: absolute;
    left: -30px;
    top: -30px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>