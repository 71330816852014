<template>
  <app-list title="Tip cheltuieli"
            :search-by="['Name']"
            show-filters
            :query-fields="[
                'ID',
                'Name',
                'DepartmentID',
                'Department {Name}',
                'CreatedAt'
            ]"
            delete-mutation-type="deleteExpenditureType"
            delete-mutation-field="ids"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="expenditureTypes"
            add-link="expenditures/add">
    <template #head>
      <lth sort-by="Name">Nume</lth>
      <lth>Departament</lth>
      <lth sort-by="CreatedAt">Data creării</lth>
    </template>
    <template #filters>
      <v-col cols="12" md="3" lg="3" xl="2">
      <lf field="DepartmentID" multiselect :items="departments" label="Departament"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd :link="`expenditures/edit/${item.ID}`">{{ item.Name }}</ltd>
      <ltd :link="`departments/edit/${item.DepartmentID}`">{{ item.Department ? item.Department.Name : '' }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  data() {
    return {
      departments: [],
    }
  },

  created() {
    this.$store.commit('setListSort', {
      direction: 'asc',
      field: 'Name'
    });
  },

  mounted() {
    let query = `Order: [
      {
        FieldName: "Name",
        Direction: "ASC"
      }
    ]`;
    this.getGQLItems('departments', [
      'Name',
      'ID',
    ], query).then(res => {
      this.departments = res.data.departments.Items;
    });
  }
}
</script>