<template>
  <app-list title="Stații"
            no-search-padding
            :search-by="[
                'SerialNr',
                'NewtonInterfaceType',
              ]"
            show-filters
            export-query-type="stationsExport"
            :query-fields="[
                'ID',
                'Location {Name}',
                'Vendor {Name}',
                'Game {Name}',
                'VendorModel {Name}',
                'Cabinet {Name}',
                'SerialNr',
                'LicenseDate',
                'ResetDate',
                'CvtDate',
                'ProfitMultiplier',
                'NewtonID',
                'FabricationYear',
            ]"
            query-type="stations">
    <template #head>
      <lth width="120">Sala</lth>
      <lth sort-by="NewtonID">ID</lth>
      <lth sort-by="SerialNr" width="120">SerialNr</lth>
      <lth>Producător</lth>
      <lth>Cabinet</lth>
      <lth>Model</lth>
      <lth>Denumire joc</lth>
      <lth>Denominare</lth>
      <lth sort-by="LicenseDate" width="150">LicenseDate</lth>
      <lth sort-by="ResetDate" width="150">ResetDate</lth>
      <lth sort-by="CvtDate" width="150">CvtDate</lth>
    </template>
    <template #filters>
      <v-col class="pt-0" cols="12" md="4" lg="3" xl="1">
        <lf field="LocationID" multiselect :items="locations" label="Sala"/>
      </v-col>
      <v-col class="pt-0" cols="12" md="4" lg="3" xl="1">
        <lf field="VendorID" multiselect :items="vendors" label="Producător"/>
      </v-col>
      <v-col class="pt-0" cols="12" md="4" lg="3" xl="1">
        <lf field="CabinetID" multiselect :items="cabinets" label="Cabinet"/>
      </v-col>
      <v-col class="pt-0" cols="12" md="4" lg="3" xl="1">
        <lf field="VendorModelID" multiselect :items="vendorModels" label="Model"/>
      </v-col>
      <v-col class="pt-0" cols="12" md="4" lg="3" xl="1">
        <lf field="GameID" multiselect :items="games" label="Denumire joc"/>
      </v-col>
      <v-col class="pt-0" cols="12" md="4" lg="2" xl="1">
        <lf modal field="LicenseDate" date label="LicenseDate"/>
      </v-col>
      <v-col class="pt-0" cols="12" md="4" lg="2" xl="1">
        <lf modal field="ResetDate" date label="ResetDate"/>
      </v-col>
      <v-col class="pt-0" cols="12" md="4" lg="2" xl="1">
        <lf modal field="CvtDate" date label="CvtDate"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd>{{ item.Location ? item.Location.Name : ''}}</ltd>
      <ltd :link="`stations/edit/${item.ID}`">{{ item.NewtonID }}</ltd>
      <ltd>{{ item.SerialNr }}</ltd>
      <ltd>{{ item.Vendor ? item.Vendor.Name : '' }}</ltd>
      <ltd>{{ item.Cabinet ? item.Cabinet.Name : '' }}</ltd>
      <ltd>{{ item.VendorModel ? item.VendorModel.Name : '' }}</ltd>
      <ltd>{{ item.Game ? item.Game.Name : '' }}</ltd>
      <ltd>{{ item.ProfitMultiplier }}</ltd>
      <ltd date>{{ item.LicenseDate }}</ltd>
      <ltd date>{{ item.ResetDate }}</ltd>
      <ltd date>{{ item.CvtDate }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  data: () => ({
    games: [],
    locations: [],
    cabinets: [],
    vendors: [],
    vendorModels: [],
  }),

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });
    this.getGQLItems('games', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.games = res.data.games.Items;
    });
    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.vendors = res.data.vendors.Items;
    });
    this.getGQLItems('cabinets', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.cabinets = res.data.cabinets.Items;
    });
    this.getGQLItems('vendorModels', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.vendorModels = res.data.vendorModels.Items;
    });
  }
}
</script>