var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getLocationID)?_c('app-list',{attrs:{"title":"Registru de casă","query-fields":[
              'ID',
              'OperationalDate',
              'Incoming',
              'Outgoing',
              'Profit',
              'NrOfIncomingTransactions',
              'NrOfOutgoingTransactions',
              'PreviousBalance',
              'LocationID',
              'Balance',
              'Location {Name}',
              'ClosedBy {FullName}',
          ],"single-select":"","query-params":_vm.params,"delete-mutation-type":"deleteClosedCashRegister","delete-mutation-field":"id","delete-by":_vm.getRole === 'admin' || _vm.getRole === 'operations' ? 'ID' : null,"height":210,"query-type":"closedCashRegisters"},scopedSlots:_vm._u([{key:"filters",fn:function(){return [(_vm.getRole !== 'manager' && _vm.getRole !== 'marketing')?_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3","xl":"2"}},[_c('lf',{attrs:{"clearable":false,"field":"LocationID","select":"","items":_vm.locations,"label":"Locație"},model:{value:(_vm.selectedLocationID),callback:function ($$v) {_vm.selectedLocationID=$$v},expression:"selectedLocationID"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3","xl":"2"}},[_c('lf',{attrs:{"modal":"","field":"OperationalDate","date":"","label":"Data operațională"}})],1)]},proxy:true},{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Date"}},[_vm._v("Data")]),_c('lth',[_vm._v("Sold start")]),_c('lth',[_vm._v("Sold end")]),_c('lth',[_vm._v("Manager")]),_c('lth',[_vm._v("Registru de casa")]),_c('lth',[_vm._v("Decont")])]},proxy:true},{key:"row",fn:function({item}){return [_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.OperationalDate))]),_c('ltd',{attrs:{"amount":""}},[_vm._v(_vm._s(item.PreviousBalance))]),_c('ltd',{attrs:{"amount":""}},[_vm._v(_vm._s(item.Balance))]),_c('ltd',[_vm._v(" "+_vm._s(item.ClosedBy ? item.ClosedBy.FullName : '')+" ")]),_c('ltd',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closedCashRegisterExport(item.OperationalDate)}}},[_c('v-icon',[_vm._v("download")])],1)],1),_c('ltd',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closedCashRegisterDecontExport(item.OperationalDate)}}},[_c('v-icon',[_vm._v("download")])],1)],1)]}}],null,false,90356402)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }