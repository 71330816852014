var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-form',{attrs:{"query-fields":[
            'Name',
            'NewtonName',
            'Address',
            'HallNr',
            'SimpleReportSync',
            'ID',
          ],"delete-mutation-type":"deleteLocation","route-param":"id","delete-field":"locationIDs","mutation-type":"saveLocation","query-type":"location","title":"Locație"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('app-form-section',[_c('app-form-section-block',{attrs:{"icon":"info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"Nume"},model:{value:(_vm.item.Name),callback:function ($$v) {_vm.$set(_vm.item, "Name", $$v)},expression:"item.Name"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"Adresă"},model:{value:(_vm.item.Address),callback:function ($$v) {_vm.$set(_vm.item, "Address", $$v)},expression:"item.Address"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"Newton Nume"},model:{value:(_vm.item.NewtonName),callback:function ($$v) {_vm.$set(_vm.item, "NewtonName", $$v)},expression:"item.NewtonName"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"HallNr"},model:{value:(_vm.item.HallNr),callback:function ($$v) {_vm.$set(_vm.item, "HallNr", _vm._n($$v))},expression:"item.HallNr"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-checkbox',{attrs:{"label":"Report sync"},model:{value:(_vm.item.SimpleReportSync),callback:function ($$v) {_vm.$set(_vm.item, "SimpleReportSync", $$v)},expression:"item.SimpleReportSync"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }