<template>
  <app-form :query-fields="queryFields"
            :mutation-fields="mutationFields"
            :attachments="attachments"
            @get="get"
            ref="form"
            @save="save"
            :prevent-save="autoCreateMultiple"
            v-model="item"
            delete-mutation-type="deletePayment"
            route-param="id"
            :hide-delete-button="closedCashRegister"
            :hide-save-button="!isClosedDay && closedCashRegister && !!$route.params.id"
            query-id-field="id"
            delete-field="ids"
            :mutation-type="isClosedDay && closedCashRegister ? 'savePaymentMeta' : 'savePayment'"
            query-type="payment"
            :title="`Registru de casă / ${!toBoolean(item.Direction) ? 'Incasare' : 'Plată'}`">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>

          <template v-if="!$route.query.type">
            <v-col class="pb-0" cols="6">
              <v-autocomplete v-model="item.TypeID"
                              :items="paymentTypes"
                              :readonly="closedCashRegister"
                              @change="handleTypeChange($event, false, true)"
                              item-text="Name"
                              solo-inverted flat outlined
                              item-value="ID"
                              :rules="[getMainRules.required]" label="Tip"/>
            </v-col>
            <v-col class="pb-0" cols="6">
              <v-autocomplete v-model="item.SubtypeID"
                              :items="paymentSubtypes"
                              :readonly="closedCashRegister"
                              :disabled="paymentSubtypes.length <= 0" clearable
                              @change="handleTypeChange($event, true, true)"
                              @click:clear="handleTypeChange(item.TypeID)"
                              item-text="Name"
                              :rules="paymentSubtypes.length > 0 ? [getMainRules.required] : []"
                              solo-inverted flat outlined
                              item-value="ID" label="Sub tip"/>
            </v-col>
          </template>
          <v-col v-if="getRole !== 'manager' && getRole !== 'marketing'" class="pb-0" cols="6">
            <template v-if="autoCreateMultiple">
              <v-autocomplete v-model="multipleLocations"
                              :items="locations"
                              multiple
                              :readonly="closedCashRegister"
                              item-text="Name"
                              :rules="[getMainRules.required]"
                              solo-inverted flat outlined
                              item-value="ID" label="Locații multiple"/>
            </template>
            <template v-else>
              <v-autocomplete v-model="item.LocationID"
                              :items="locations"
                              :readonly="closedCashRegister"
                              item-text="Name"
                              :rules="[getMainRules.required]"
                              solo-inverted flat outlined
                              item-value="ID" label="Locație"/>
            </template>
          </v-col>
        </v-row>
      </app-form-section-block>
      <app-form-section-block>
        <v-row>

          <v-col cols="12" v-if="$route.params.type === 'payment'">
            <app-file
                multiple
                :disabled-add="closedCashRegister"
                :disabled-delete="closedCashRegister"
                v-model="attachments"
                :items="item.Attachments"
                accept=".pdf"
                outlined
                label="Atașează fișiere"
            />
          </v-col>
          <v-col cols="6" v-if="appearance.Date">
            <app-datepicker v-model="item.Date" :rules="[getMainRules.required]"
                            :readonly="closedCashRegister"
                            outlined label="Data operațională"/>
          </v-col>

          <v-col cols="6" v-if="appearance.DocumentNr">
            <v-text-field v-model="item.OtherInfo.DocumentNr" :rules="[getMainRules.required]"
                          :readonly="closedCashRegister"
                          outlined label="Nr. Document"/>
          </v-col>
          <v-col cols="6" v-if="appearance.Amount">
            <v-text-field v-model.number="item.Amount" type="number" :rules="[getMainRules.required]"
                          :readonly="closedCashRegister"
                          outlined label="Suma"/>
          </v-col>

          <v-col cols="6" v-if="appearance.Explanation">
            <v-text-field v-model="item.Explanation"
                          :readonly="closedCashRegister"
                          outlined label="Explicație"/>
          </v-col>
          <v-col cols="6" v-if="appearance.VendorID">
            <v-autocomplete :items="inventoryVendors"
                            item-text="Name"
                            item-value="ID"
                            v-model="item.VendorID"
                            :readonly="closedCashRegister"
                            :rules="[getMainRules.required]" outlined label="Furnizor"/>
          </v-col>
          <v-col cols="6" v-if="appearance.InvoiceDate">
            <app-datepicker v-model="item.OtherInfo.InvoiceDate" :rules="[getMainRules.required]"
                            :readonly="closedCashRegister"
                            outlined label="Data factură"/>
          </v-col>

          <v-col cols="6" v-if="appearance.InvoiceNr">
            <v-text-field v-model="item.OtherInfo.InvoiceNr" :rules="[getMainRules.required]"
                          :readonly="closedCashRegister"
                          outlined label="Nr. Factură"/>
          </v-col>
          <v-col cols="6" v-if="appearance.NrOfPosts">
            <v-text-field v-model="item.OtherInfo.NrOfPosts" :rules="[getMainRules.required]"
                          :readonly="closedCashRegister"
                          outlined label="Numar Posturi"/>
          </v-col>
          <v-col cols="6" v-if="appearance.ReceiptNr">
            <v-text-field v-model="item.OtherInfo.ReceiptNr" :rules="[getMainRules.required]"
                          :readonly="closedCashRegister"
                          outlined label="Nr. Bon"/>
          </v-col>
          <v-col cols="6" v-if="appearance.PVNr">
            <v-text-field v-model="item.OtherInfo.PVNr" :rules="[getMainRules.required]"
                          :readonly="closedCashRegister"
                          outlined label="Nr. Proces Verbal"/>
          </v-col>
          <v-col cols="6" v-if="appearance.PVDate">
            <app-datepicker v-model="item.OtherInfo.PVDate" :rules="[getMainRules.required]"
                            :readonly="closedCashRegister"
                            outlined label="Data Proces Verbal"/>
          </v-col>
          <v-col cols="6" v-if="appearance.TombolaName">
            <v-text-field v-model="item.OtherInfo.TombolaName" :rules="[getMainRules.required]"
                          :readonly="closedCashRegister"
                          outlined label=" Denumire Tombola"/>
          </v-col>

          <v-col cols="6" v-if="appearance.DepartmentID">
            <v-autocomplete v-model="item.DepartmentID"
                            :items="departments"
                            item-text="Name"
                            :rules="[getMainRules.required]"
                            @change="handleDepartmentChange($event, true)"
                            item-value="ID"
                            outlined label="Departament"/>
          </v-col>
          <v-col cols="6" v-if="appearance.ExpenditureTypeID">
            <v-autocomplete v-model="item.ExpenditureTypeID"
                            :items="expenditureTypes"
                            :rules="[getMainRules.required]"
                            @change="handleExpenditureTypeChange"
                            item-text="Name"
                            item-value="ID"
                            outlined label="Tip cheltuiala"/>
          </v-col>
          <template v-if="isClosedDay">
            <v-col cols="6" v-if="appearance.Details">
              <v-text-field v-model="item.Details"
                            :readonly="closedCashRegister"
                            outlined label="Detalii"/>
            </v-col>
          </template>

        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    let routeParam = this.$route.params.type;
    let date = this.$moment().subtract('1', 'days').format('YYYY-MM-DD');
    return {
      item: {
        Direction: routeParam === 'cash' ? 0 : 1,
        TypeID: null,
        SubtypeID: null,
        LocationID: null,
        Attachments: [],
        Date: date,
        OtherInfo: {
          InvoiceNr: null,
          PVDate: null,
          ReceiptNr: null,
          PVNr: null,
          InvoiceDate: null,
          NrOfPosts: null,
          DocumentNr: null,
        },
        ClosedCashRegister: null,
      },

      multipleLocations: [],

      unchangedItem: {},

      queryFields: [
        'ID',
        'Date',
        'Explanation',
        'Amount',
        'LocationID',
        'DepartmentID',
        'ClosedCashRegister { ID }',
        'ExpenditureTypeID',
        'Attachments {Name, OriginalName, ID, Size, Extension}',
        'Direction',
        'OtherInfo {InvoiceNr, InvoiceDate, ReceiptNr, DocumentNr, NrOfPosts, TombolaName, PVDate, PVNr}',
        'TypeID',
        'SubtypeID',
        'VendorID',
      ],

      mutationFields: [
        'ID',
        'Date',
        'Explanation',
        'Amount',
        'LocationID',
        'DepartmentID',
        'ExpenditureTypeID',
        'Direction',
        'OtherInfo {InvoiceNr, InvoiceDate, ReceiptNr, DocumentNr, NrOfPosts, TombolaName, PVDate, PVNr}',
        'TypeID',
        'SubtypeID',
        'VendorID',
      ],

      inventoryVendors: [],
      expenditureTypes: [],
      paymentSubtypes: [],
      locations: [],
      departments: [],

      attachments: [],
      appearance: {
        Date: null,
      },

      paymentTypes: [],
      paymentSubTypes: [],

      restOfFieldsChanged: false,
      typeChanged: false,
      departmentChanged: false,
    }
  },

  watch: {
    paymentTypes: function () {
      if (this.item.TypeID) {
        this.handleTypeChange(this.item.TypeID);
      }
    },

    item: {

      deep: true,

      handler(item) {
        item = this.clone(item);
        Object.keys(item).every(key => {
          let value = item[key];
          let value2 = this.unchangedItem[key];

          if (value !== value2) {
            if (key !== 'DepartmentID' && key !== 'ExpenditureTypeID' && typeof value !== 'object') {
              console.log(key, value, value2);
              this.restOfFieldsChanged = true;
              return false;
            }
          }
          return true;
        });
      }
    },

    paymentSubtypes: function () {
      if (this.item.SubtypeID) {
        this.handleTypeChange(this.item.SubtypeID, true);
      }
    },
  },

  mounted() {
    if (this.getRole !== 'manager' && this.getRole !== 'marketing') {
      this.getGQLItems('locations', [
        'Name',
        'ID',
      ]).then(res => {
        this.locations = res.data.locations.Items;
      });
    }

    if (this.getRole === 'manager' || this.getRole === 'marketing') {
      this.item.LocationID = this.getUser.LocationID;
    }
    this.getGQLItems('inventoryVendors', [
      'Name',
      'ID',
    ]).then(res => {
      this.inventoryVendors = res.data.inventoryVendors.Items;
    });

    this.getGQLItems('departments', [
      'Name',
      'ID',
    ]).then(res => {
      this.departments = res.data.departments.Items;
    });

    if (!this.$route.params.id) {
      this.getPaymentTypes();
    }
  },

  methods: {
    get: function () {
      this.getPaymentTypes();
      this.handleDepartmentChange(this.item.DepartmentID);

      this.restOfFieldsChanged = false;
      this.unchangedItem = this.clone(this.item);
    },

    save: function (back = false) {
      if (this.autoCreateMultiple) {
        let eachAmount = this.round(this.item.Amount / this.multipleLocations.length);
        let promises = [];
        for (let i = 0; i < this.multipleLocations.length; i++) {
          let lId = this.multipleLocations[i];
          this.item.LocationID = lId;
          this.item.Amount = eachAmount;
          this.item.ID = "";

          let promise = this.$refs.form.save(false);
          promises.push(promise);
        }

        if (back) {
          Promise.all(promises).then(() => {
            this.$router.back();
          });
        }
      } else {
        if (this.isClosedDay) {
          this.typeChanged = false;
          this.departmentChanged = false;
        } else {
          this.restOfFieldsChanged = false;
        }
        this.unchangedItem = this.clone(this.item);
      }

      if (!this.$route.query.id) {
        this.attachments = [];
      }
    },

    handleDepartmentChange: function (id, manual = false) {
      let query = this.getSingleQuery('DepartmentID', id);
      this.getGQLItems('expenditureTypes', [
        'Name',
        'ID',
        'DepartmentID',
      ], query).then(res => {
        this.expenditureTypes = res.data.expenditureTypes.Items;
      });

      if (manual) {
        this.departmentChanged = true;
      }
    },

    handleExpenditureTypeChange: function () {
      this.typeChanged = true;
    },

    handleTypeChange: function (id, subtype = false, r = false) {
      let items = subtype ? this.paymentSubtypes : this.paymentTypes;
      let type = items.find(i => i.ID === id);

      if (type) {
        if (r) {
          Object.keys(this.item.OtherInfo).forEach(k => {
            this.item.OtherInfo[k] = null;
          });

          this.item.TombolaName = null;
          this.item.Details = null;
          this.item.Explanation = null;
          if (this.item.ID) {
            this.item.Date = null;
          }
          this.item.DepartmentID = null;
          this.item.VendorID = null;
          this.item.Amount = 0;
          this.item.ExpenditureTypeID = null;
        }

        if (!subtype && type.Subtypes.length === 0) {
          this.appearance = type.Appearance;
          this.item.SubtypeID = null;
        } else if (subtype) {
          if (type.Appearance) {
            this.appearance = type.Appearance;
          } else if (!type.Appearance && type.parent) {
            this.appearance = type.parent.Appearance;
          }
        } else {
          this.appearance = {};
        }


        if (type.Subtypes && !subtype) {
          type.Subtypes.forEach(i => {
            i.parent = type;
          });
          this.paymentSubtypes = type.Subtypes;
        }
      }
    },

    getPaymentTypes: function () {
      this.getGQLItems('paymentTypes', [
        'Name',
        'TransactionDirection',
        'ID',
        `Subtypes {
        Name,
        ID,
        Appearance {
          InvoiceNr,
          Amount,
          VendorID,
          InvoiceDate,
          ReceiptNr,
          DocumentNr,
          NrOfPosts,
          TombolaName,
          Date,
          PVDate,
          PVNr,
          Explanation,
          Details,
          DepartmentID,
          ExpenditureTypeID,
          LocationID
          }
         }`,
        `Appearance {
          InvoiceNr,
          VendorID,
          Amount,
          InvoiceDate,
          ReceiptNr,
          DocumentNr,
          NrOfPosts,
          TombolaName,
          Date,
          PVDate,
          PVNr,
          Explanation,
          Details,
          DepartmentID,
          ExpenditureTypeID,
          LocationID
          }
         `,
      ]).then(res => {
        let items = [];
        res.data.paymentTypes.Items.forEach(i => {
          if (i.TransactionDirection == this.item.Direction) {
            items.push(i);
          }
        });


        if (this.$route.query.type && !this.$route.params.id) {
          let ex = items.find(i => i.Name === 'Plată Birou');
          if (ex) {
            this.item.TypeID = ex.ID;
          }
        }

        this.paymentTypes = items;
      });
    }
  },

  computed: {
    isClosedDay: function () {
      if (this.departmentChanged || this.typeChanged) {
        if (!this.restOfFieldsChanged && this.$route.params.id) {
          return true;
        }
      }
      return false;
    },

    closedCashRegister: function () {
      return (!!this.item.ClosedCashRegister);
    },

    getTypeName: function () {
      let item = this.paymentTypes.find(i => i.ID === this.item.TypeID);
      if (item) {
        return item.Name;
      }
      return null;
    },

    autoCreateMultiple: function () {
      console.log('this.$route.query.type', this.$route.query.type);
      return (this.getTypeName === 'Plată Birou' && this.isAddPage && !this.$route.query.type && this.getRole !== 'manager' && this.getRole !== 'marketing');
    },

    isAddPage: function () {
      return !(this.$route.params.id);
    }
  }
}
</script>