<template>
  <div>
    <Profile>
      <v-btn icon @click.stop="$emit('update:mini', !mini)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </Profile>
    <List :items="items"/>
  </div>
</template>
<script>
import List from '@/components/app/menu/List';
import Profile from "@/components/app/menu/Profile";

export default {
  components: {
    Profile,
    List
  },
  props: {
    items: Array,
    mini: Boolean,
  }
}
</script>