<template>
  <app-form :query-fields="[
                'ID',
                'Name',
            ]"
            v-model="item"
            delete-mutation-type="deleteInventoryType"
            route-param="id"
            delete-field="ids"
            mutation-type="saveInventoryType"
            query-type="inventoryType"
            title="Tip">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Denumire"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
    }
  },
}
</script>