<template>
  <app-form :query-fields="[
                'ID',
                'FirstName',
                'UserName',
                'LastName',
                'PrimaryPhone',
                'PrimaryEmail',
                'LocationID',
                'Role',
                'IDCard',
            ]"
            v-model="item"
            @save="save"
            :mutation-params="mutationParams"
            delete-mutation-type="deleteUser"
            route-param="id"
            delete-field="userIDs"
            mutation-type="saveUser"
            query-type="user"
            title="Utilizator">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.UserName" :rules="[getMainRules.required]" outlined label="UserName"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.FirstName" :rules="[getMainRules.required]" outlined label="Prenume"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.LastName" :rules="[getMainRules.required]" outlined label="Nume"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.PrimaryPhone" outlined label="Telefon"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.IDCard" outlined label="Info. Buletin"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.PrimaryEmail" :rules="[getMainRules.required]" outlined label="Email"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select hide-details v-model="item.Role" :items="roles"
                      :rules="[getMainRules.required]" outlined label="Rol"/>
          </v-col>
          <v-col v-if="item.Role === 'manager' || item.Role === 'marketing'" cols="12" lg="6">
            <v-select hide-details v-model="item.LocationID" :items="locations" item-text="Name" item-value="ID"
                      outlined label="Sală"/>
          </v-col>
          <!--          <v-col cols="12" lg="3">-->
          <!--            <v-checkbox v-model="item.IsActive" label="Activ"/>-->
          <!--          </v-col>-->
          <v-col cols="12" lg="3">
            <v-checkbox v-model="changePassword" label="Parola"/>
          </v-col>
        </v-row>
      </app-form-section-block>
      <app-form-section-block v-if="changePassword" icon="lock">
        <v-row>
          <v-col class="pb-0" cols="12" lg="6">
            <v-text-field type="password" v-model="password"
                          :rules="[password ? getMainRules.password : true]" outlined
                          label="Parolă"/>
          </v-col>
          <v-col class="pb-0" cols="12" lg="6">
            <v-text-field :disabled="!password" type="password" v-model="passwordRepeat"
                          :rules="[getMainRules.password]" outlined
                          label="Repetă parola"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>

export default {
  data() {
    return {
      changePassword: false,
      password: null,
      passwordRepeat: null,

      locations: [],

      item: {
        IsActive: false,
      },
      roles: [
        {
          text: 'Administrator',
          value: 'admin',
        },
        {
          text: 'Operational',
          value: 'operations',
        },
        {
          text: 'Contabil',
          value: 'accounting',
        },
        {
          text: 'Marketing',
          value: 'marketing',
        },
        {
          text: 'Manager',
          value: 'manager',
        },
      ]
    }
  },

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });
  },

  methods: {
    save: function () {
      if (this.password !== this.passwordRepeat) {
        this.notify('Parolele nu coincid');
      } else {
        if (this.password && this.password === this.passwordRepeat) {
          this.notify('Parola a fost schimbat-ă cu succes');
          this.password = '';
          this.passwordRepeat = '';
        }
      }
    }
  },

  computed: {
    mutationParams: function () {
      if (this.password && this.password === this.passwordRepeat) {
        return `
        AuthDetails: {
          ChangePassword: true
          Password: "${this.password}"
        }`;
      }
      return `
      AuthDetails: {
        ChangePassword: false,
      }
      `;
    }
  }
}
</script>