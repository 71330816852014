<template>
  <app-form :query-fields="[
                'ID',
                'Name',
                'FiscalCode',
                'Address',
                'Phone',
            ]"
            v-model="item"
            delete-mutation-type="deleteInventoryVendor"
            route-param="id"
            :error-handler="errorHandler"
            delete-field="ids"
            mutation-type="saveInventoryVendor"
            query-type="inventoryVendor"
            title="Furnizor">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Denumire"/>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="item.FiscalCode" :rules="[getMainRules.required]" outlined label="FiscalCode"/>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="item.Address" :rules="[getMainRules.required]" outlined label="Address"/>
          </v-col>
          <v-col cols="6">
            <v-text-field type="number" v-model="item.Phone" outlined label="Phone"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
    }
  },

  methods: {
    errorHandler: function(msg) {
      if (msg === 'a record with this fiscal code already exists') {
        this.notify('Un astfel de furnizor avind acest cod fiscal deja exista', 'error');
      } else {
        this.notify(msg, 'error');
      }
    }
  }

}
</script>