<template>
  <v-list expand nav dense>
    <template v-for="item in items">
      <template v-if="item.items && item.items.length > 0">

        <v-list-group :value="true" no-action color="primary" :key="item.title">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="(child, co) in item.items">
            <v-list-item
                v-if="!child.divider"
                :to="toPage(child.link)"
                :exact="child.link === '/'"
                :key="child.title">
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider :key="co + item.title" v-if="child.divider"/>
          </template>

        </v-list-group>
      </template>
      <template v-else>
        <v-list-item :exact="item.link === '/'" color="primary" :to="toPage(item.link)" :key="item.title">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </template>
  </v-list>
</template>
<script>
export default {
  props: {
    items: Array,
  }
}
</script>