<template>
  <app-list title="Rapoarte simple"
            :search-by="['LocationName', 'VendorName', 'GameName', 'VendorModelName', 'StationSerialNr']"
            show-filters
            export-query-type="simpleReportsExport"
            :query-fields="[
                'LocationID',
                'StationID',
                'EventDateTime',
                'EventDate',
                'StationSerialNr',
                'LocationName',
                'VendorName',
                'CabinetName',
                'VendorModelName',
                'GameName',
                'In',
                'Out',
                'Win',
                'Bet',
                'CreatedAt',
            ]"
            query-type="simpleReports">
    <template #head>
      <lth sort-by="LocationName">Locație</lth>
      <lth sort-by="StationSerialNr">StationSerialNr</lth>
      <lth sort-by="VendorName">Producător</lth>
      <lth sort-by="VendorModelName">Model</lth>
      <lth sort-by="CabinetName">Cabinet</lth>
      <lth sort-by="GameName">Joc</lth>
      <lth sort-by="In">In</lth>
      <lth sort-by="Out">Out</lth>
      <lth sort-by="Bet">Bet</lth>
      <lth sort-by="Win">Win</lth>
      <lth sort-by="CreatedAt">Creat la</lth>
    </template>
    <template #filters>
      <v-col cols="12" md="4" lg="3">
        <lf field="CabinetID" multiselect :items="cabinets" label="Cabinet"/>
      </v-col>
      <v-col cols="12" md="4" lg="3">
        <lf field="LocationID" multiselect :items="locations" label="Sală"/>
      </v-col>
      <v-col cols="12" md="4" lg="3">
        <lf date field="EventDate" label="Creat la"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd>{{ item.LocationName }}</ltd>
      <ltd :link="`stations/edit/${item.StationID}`">{{ item.StationSerialNr }}</ltd>
      <ltd>{{ item.VendorName }}</ltd>
      <ltd>{{ item.VendorModelName }}</ltd>
      <ltd>{{ item.CabinetName }}</ltd>
      <ltd>{{ item.GameName }}</ltd>
      <ltd amount>{{ item.In }}</ltd>
      <ltd amount>{{ item.Out }}</ltd>
      <ltd amount>{{ item.Bet }}</ltd>
      <ltd amount>{{ item.Win }}</ltd>
      <ltd hour date>{{ item.EventDateTime }}</ltd>
    </template>
    <template #footer="{items}">
      <tr>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth>{{ getTotal('In', items) }}</lth>
        <lth>{{ getTotal('Out', items) }}</lth>
        <lth>{{ getTotal('Bet', items) }}</lth>
        <lth>{{ getTotal('Win', items) }}</lth>
        <lth></lth>
      </tr>
    </template>
  </app-list>
</template>
<script>
export default {

  data: () => ({
    cabinets: [],
    locations: [],
  }),

  created() {
    this.$store.commit('setListSort', {
      direction: 'desc',
      field: 'CreatedAt'
    });
  },

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('cabinets', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.cabinets = res.data.cabinets.Items;
    });

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });
  },

  methods: {
    getLink: function(i) {
      let id = btoa(JSON.stringify({
        EventDateTime: i.EventDateTime,
        EventDate: i.EventDate,
        StationID: i.StationID,
        LocationID: i.LocationID,
      }));
      return `simple-reports/edit/${id}`;
    }
  },

}
</script>