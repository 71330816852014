export default {

    methods: {
        getTotal: function (field, items) {
            let total = 0;
            items.forEach(i => {
                total += parseFloat(i[field]);
            });
            return this.formatAmount(this.round(total));
        }
    },

    computed: {
        getList: function () {
            return this.$store.getters.getList;
        }
    },

}