<template>
  <app-form :query-fields="[
                'ID',
                'Name',
                'InventoryTypeID',
            ]"
            v-model="item"
            delete-mutation-type="deleteInventoryModel"
            route-param="id"
            delete-field="ids"
            mutation-type="saveInventoryModel"
            query-type="inventoryModel"
            title="Model">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="6">
          <v-autocomplete v-model="item.InventoryTypeID" :items="inventoryTypes"
                            item-text="Name"
                            item-value="ID"
                            :rules="[getMainRules.required]"
                            outlined label="Tip"/>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Denumire"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
      inventoryTypes: [],
    }
  },

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('inventoryTypes', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.inventoryTypes = res.data.inventoryTypes.Items;
    });
  }
}
</script>