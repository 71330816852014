<template>
  <app-list title="Rapoarte producători"
            @actions="dense = $event.dense"
            show-filters
            no-search-padding
            export-query-type="stationVendorsReportExport"
            :search-by="['VendorName']"
            default-input-name="filterInput"
            :additional-query-params="params"
            :height="180"
            dense-filters
            :query-params="getParams"
            :query-fields="[
                'VendorName',
                'TotalIn',
                'TotalOut',
                'TotalBet',
                'TotalWin',
                'TotalProfit',
            ]"
            query-type="stationVendorsReport">
    <template #head>
      <lth>Vendor</lth>
      <lth sort-by="TotalIn">TotalIn</lth>
      <lth sort-by="TotalOut">TotalOut</lth>
      <lth sort-by="TotalBet">TotalBet</lth>
      <lth sort-by="TotalProfit">TotalProfit</lth>
      <lth sort-by="TotalWIn">TotalWin</lth>
    </template>
    <template #filters>
      <v-col cols="12" md="3" lg="3" xl="2">
        <app-range-picker :value="[params.customFilterInput.StartDateUtc, params.customFilterInput.EndDateUtc]"
                          :dense="dense" outlined label="Filtreaza după dată" @input="handleDateChange"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf v-model="params.customFilterInput.CabinetID" multiselect independent field="CabinetID" :items="cabinets" label="Cabinet"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf v-model="params.customFilterInput.VendorID" multiselect independent field="VendorID" :items="vendors" label="Producător"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf v-model="params.customFilterInput.GameID" multiselect independent field="GameID" :items="games" label="Denumire joc"/>
      </v-col>
      <v-col v-if="getRole === 'admin' || getRole === 'operations'" cols="12" md="3" lg="3" xl="2">
        <lf v-model="params.customFilterInput.LocationID" field="LocationID" independent multiselect :items="locations" label="Locație"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd>{{ item.VendorName }}</ltd>
      <ltd amount>{{ item.TotalIn }}</ltd>
      <ltd amount>{{ item.TotalOut }}</ltd>
      <ltd amount>{{ item.TotalBet }}</ltd>
      <ltd amount>{{ item.TotalProfit }}</ltd>
      <ltd amount>{{ item.TotalWin }}</ltd>
    </template>
    <template #footer="{items}">
      <tr>
        <lth></lth>
        <lth>{{ getTotal('TotalIn', items) }}</lth>
        <lth>{{ getTotal('TotalOut', items) }}</lth>
        <lth>{{ getTotal('TotalBet', items) }}</lth>
        <lth>{{ getTotal('TotalProfit', items) }}</lth>
        <lth>{{ getTotal('TotalWin', items) }}</lth>
      </tr>
    </template>
  </app-list>
</template>
<script>
export default {
  data() {
    // let date1 = this.$moment().startOf('year').format('YYYY-MM-DD');
    let date1 = this.$moment().format('YYYY-MM-DD');
    // let date2 = this.$moment().endOf('year').format('YYYY-MM-DD');
    let date2 = this.$moment().format('YYYY-MM-DD');

    return {
      dense: false,

      vendors: [],
      cabinets: [],
      games: [],
      locations: [],

      params: {
        customFilterInput: {
          LocationID: [],
          GameID: [],
          VendorID: [],
          CabinetID: [],
          StartDateUtc: date1,
          EndDateUtc: date2,
        }
      }
    }
  },

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('cabinets', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.cabinets = res.data.cabinets.Items;
    });

    this.getGQLItems('games', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.games = res.data.games.Items;
    });

    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.vendors = res.data.vendors.Items;
    });

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });
  },

  methods: {
    handleDateChange: function (val) {
      this.params.customFilterInput.StartDateUtc = val[0];
      this.params.customFilterInput.EndDateUtc = val[1];
    },
  },

  computed: {
    getParams: function() {
      if (this.getRole === 'manager') {
        return `{
              Eq: {
                Name: "LocationID"
                Value: "${this.getUser.LocationID}"
              }
            }`;
      }
      return ``;
    }
  }
}
</script>