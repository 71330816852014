<template>
  <app-form :query-fields="[
              'Explanation',
              'DepartmentID',
              'LocationID',
              'ExpenditureTypeID',
              'Amount',
              'Date',
              'ID',
            ]"
            v-model="item"
            @get="get"
            delete-mutation-type="deleteExpenditure"
            route-param="id"
            query-id-field="id"
            delete-field="expenditureIDs"
            mutation-type="savePayment"
            query-type="payment"
            title="Cheltuiala">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12">
            <v-autocomplete @change="getExpenditures"
                            :items="departments" v-model="item.DepartmentID" item-text="Name" item-value="ID" outlined
                            label="Departament"/>
          </v-col>
          <v-col cols="12">
            <v-autocomplete :items="expenditures" v-model="item.ExpenditureTypeID"
                            item-text="Name" item-value="ID" outlined label="Tip cheltuiala"/>
          </v-col>
          <v-col cols="12">
            <app-datepicker v-model="item.Date" :rules="[getMainRules.required]" outlined label="Data"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model.number="item.Amount" :rules="[getMainRules.required]" outlined label="Suma"/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="item.Explanation" outlined label="Furnizor/Detalii"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {
        Date: null,
      },
      departments: [],
      locations: [],
      expenditures: [],
    }
  },

  mounted() {
    if (this.getRole === 'manager') {
      this.item.LocationID = this.getUser.LocationID;
    }

    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });

    this.getGQLItems('departments', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.departments = res.data.departments.Items;
    });
  },

  methods: {
    get: function () {
      if (this.getRole === 'manager') {
        this.item.LocationID = this.getUser.LocationID;
      }
      this.getExpenditures(this.item.DepartmentID);
    },

    getExpenditures: function (value) {
      let query = this.getSingleQuery('DepartmentID', value);
      this.getGQLItems('expenditureTypes', [
        'Name',
        'ID',
      ], query).then(res => {
        this.expenditures = res.data.expenditureTypes.Items;
      });
    }
  }
}
</script>