<template>
  <v-menu
      ref="menu"
      :close-on-content-click="true"
      v-model="menu"
      transition="scale-transition"
      offset-y
      left
      min-width="auto">
    <template v-slot:activator="{ on }">
      <v-text-field
          v-on="on"
          :label="label"
          :dense="dense"
          hide-details
          :outlined="outlined"
          :disabled="disabled"
          :append-icon="icon"
          :color="color"
          :clearable="clearable"
          :solo="solo"
          :flat="flat"
          readonly
          :value="date"/>
    </template>
    <v-date-picker
        reactive
        show-current
        ref="picker"
        v-model="date"
        min="1960"
        :max="getCurrentYear + 1"
        no-title>
    </v-date-picker>
  </v-menu>
</template>
<script>

export default {
  data() {
    return {
      year: '' + this.value,
      menu: false,
      modal: false,
    }
  },

  props: {
    outlined: Boolean,
    disabled: Boolean,
    solo: Boolean,
    flat: Boolean,
    clearable: Boolean,
    dense: Boolean,
    value: {
      default() {
        return ''
      }
    },
    color: {
      default() {
        return 'primary'
      }
    },
    icon: {
      default() {
        return 'date_range'
      }
    },
    label: {
      default() {
        return ''
      }
    }
  },

  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.internalActivePicker = 'YEAR'))
    },

    value: function (value) {
      this.year = '' + value;
    }
  },
  computed: {
    getCurrentYear: function () {
      return this.$moment().format('YYYY');
    },

    date: {
      get: function () {
        if (!this.year || this.year === 'null') {
          return null;
        }
        if (this.year.length > 4) {
          return this.$moment(this.year).format('YYYY');
        }
        return this.year;
      },
      set: function (newValue) {
        if (newValue.length <= 10) {
          this.year = (newValue.slice(0, -6));
        } else {
          this.year = (newValue.slice(0, -8));
        }
        this.$emit('input', parseInt(this.year));
        this.$emit('change', parseInt(this.year));
      },
    },
  },
};

</script>
