var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-form',{attrs:{"query-fields":[
            'VendorModelID',
            'GameID',
            'CabinetID',
            'LocationID',
            'StationID',
            'EventDateTime',
            'EventDate',
            'CreatedAt',
          ],"route-param":"id","query-object-params":_vm.query,"query-type":"simpleReport","title":"Raport simplu"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('app-form-section',[_c('app-form-section-block',{attrs:{"icon":"info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-autocomplete',{attrs:{"item-text":"Name","item-value":"ID","items":_vm.games,"outlined":"","label":"Denumire Joc"},model:{value:(_vm.item.GameID),callback:function ($$v) {_vm.$set(_vm.item, "GameID", $$v)},expression:"item.GameID"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-autocomplete',{attrs:{"item-text":"Name","item-value":"ID","items":_vm.vendors,"outlined":"","label":"Producător"},model:{value:(_vm.item.VendorID),callback:function ($$v) {_vm.$set(_vm.item, "VendorID", $$v)},expression:"item.VendorID"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-autocomplete',{attrs:{"item-text":"Name","item-value":"ID","items":_vm.cabinets,"outlined":"","label":"Cabinet"},model:{value:(_vm.item.CabinetID),callback:function ($$v) {_vm.$set(_vm.item, "CabinetID", $$v)},expression:"item.CabinetID"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-autocomplete',{attrs:{"item-text":"Name","item-value":"ID","items":_vm.models,"outlined":"","label":"Model"},model:{value:(_vm.item.VendorModelID),callback:function ($$v) {_vm.$set(_vm.item, "VendorModelID", $$v)},expression:"item.VendorModelID"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }