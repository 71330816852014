<template>
  <app-list title="Jocuri"
            :search-by="['Name']"
            show-filters
            :query-fields="[
                'ID',
                'VendorID',
                'Vendor {Name}',
                'Name',
                'NrOfStations',
                'CreatedAt'
            ]"
            delete-mutation-type="deleteGame"
            delete-mutation-field="gameIDs"
            export-query-type="gamesExport"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="games"
            add-link="games/add">
    <template #head>
      <lth sort-by="Name">Nume</lth>
      <lth>Producător</lth>
      <lth sort-by="NrOfStations">NrOfStations</lth>
      <lth sort-by="CreatedAt">Data creării</lth>
    </template>
    <template #row="{item}">
      <ltd :link="`games/edit/${item.ID}`">{{ item.Name }}</ltd>
      <ltd :link="`vendors/edit/${item.VendorID}`">{{ item.Vendor ? item.Vendor.Name : '' }}</ltd>
      <ltd>{{ item.NrOfStations }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  created() {
    this.$store.commit('setListSort', {
      direction: 'asc',
      field: 'Name'
    });
  }
}
</script>