<template>
  <v-app>
    <router-view/>
  </v-app>
</template>
<script>
export default {

  watch: {
      '$route.path': function() {
        this.$store.commit('setListSort', {
          direction: '',
          field: ''
        });
        this.$store.commit('setListFilters', []);
      }
  },

}
</script>