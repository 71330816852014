<template>
  <app-list title="Cheltuieli"
            show-filters
            :row-click="link"
            v-if="operationalDate"
            :query-fields="[
                'ID',
                'Amount',
                'Date',
                'Explanation',
                'DocumentNrTranslated',
                'DepartmentID',
                'Department {Name}',
                'Location {Name}',
                'Type {Name}',
                'ExpenditureType {Name}',
                'CreatedAt',
                'Attachments {ID}',
                'CreatedBy {FirstName, LastName}',
              ]"
            query-type="expenditures"
            dense-filters
            no-start-request
            :height="230"
            :query-params="params"
            export-query-type="expendituresExport">
    <template #actions>
      <v-btn outlined text color="success" exact :to="toPage(`transactions/payment/add?type=Plata-Birou`)"
             class="ml-1 v-btn--active">
        <v-icon>add</v-icon>
        Adaugă plată
      </v-btn>
    </template>
    <template #head>
      <lth v-if="getRole === 'admin' || getRole === 'operations'">Locație</lth>
      <lth sort-by="Date">Dată tranzacție</lth>
      <lth>Tip tranzacție</lth>
      <lth>Nr Document</lth>
      <lth width="30"></lth>
      <lth sort-by="Amount">Suma</lth>
      <lth sort-by="Explanation">Detalii</lth>
      <lth>Departament</lth>
      <lth>Tip cheltuiala</lth>
      <lth sort-by="CreatedAt">Data creării</lth>
      <lth>Creat de</lth>
    </template>
    <template #filters>
      <v-col v-if="getRole === 'admin' || getRole === 'operations'" cols="12" md="4" lg="3" xl="2">
        <lf field="LocationID" multiselect :items="locations" label="Locație"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf @input="handleOperationalDate" date label="Dată operațională" field="OperationalDate"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf field="TypeID" multiselect :items="paymentTypes" label="Tip tranzacție"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf field="DepartmentID" @change="handleDepartmentChange" select :items="departments" label="Department"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf field="ExpenditureTypeID" :disabled="expenditureTypes.length === 0" multiselect :items="expenditureTypes" label="Tip cheltuială"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf multiselect field="VendorID" :items="inventoryVendors" label="Furnizor"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf date label="Data creării" field="CreatedAt"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf multiselect :items="users" label="Creat de" field="CreatedByID"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf text label="Detalii" field="Explanation"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd v-if="getRole === 'admin' || getRole === 'operations'">{{ item.Location ? item.Location.Name : '' }}</ltd>
      <ltd date>{{ item.Date }}</ltd>
      <ltd>
        {{ item.Type ? item.Type.Name : '' }}
      </ltd>
      <ltd>
        {{ item.DocumentNrTranslated }}
      </ltd>
      <ltd>
        <template v-if="item.Attachments.length > 0">
          <v-icon>attach_file</v-icon>
        </template>
      </ltd>
      <ltd amount>{{ item.Amount }}</ltd>
      <ltd>{{ item.Explanation }}</ltd>
      <ltd>{{ item.Department ? item.Department.Name : '' }}</ltd>
      <ltd>
        {{ item.ExpenditureType ? item.ExpenditureType.Name : '' }}
      </ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
      <ltd>{{ item.CreatedBy ? item.CreatedBy.FirstName : '' }} {{ item.CreatedBy ? item.CreatedBy.LastName : '' }}
      </ltd>
    </template>
    <template #footer="{items}">
      <tr>
        <lth v-if="getRole === 'admin'|| getRole === 'operations'"></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth>{{ getTotal('Amount', items) }}</lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth></lth>
      </tr>
    </template>
  </app-list>
</template>
<script>
import gql from "graphql-tag";

export default {

  data() {
    return {
      expenditureTypes: [],
      locations: [],
      departments: [],
      paymentTypes: [],
      users: [],
      operationalDate: null,
      inventoryVendors: [],
    }
  },

  created() {
    this.$store.commit('setListSort', {
      direction: 'desc',
      field: 'CreatedAt'
    });

    let filters = [];
    if (this.$route.query.filters) {
      filters = JSON.parse(this.$route.query.filters);
    }
    let existentFilter = filters.find(f => f.field === 'OperationalDate');
    if (!existentFilter) {
      let localOperationalDate = localStorage.getItem('app.list.transactions.filters.operational_date');

      if (localOperationalDate) {
        localOperationalDate = localOperationalDate.split(',');
        this.operationalDate = localOperationalDate;
        this.filterOperationalDate(false);
      } else {
        this.$apollo.query({
          query: gql`query {
          currentOperationalDate
      }`
        }).then(res => {
          this.operationalDate = res.data.currentOperationalDate;
          this.filterOperationalDate();
        });
      }


    } else {
      this.operationalDate = existentFilter.value[0];
    }

  },

  methods: {
    handleDepartmentChange: function(value) {
      let query = this.getSingleQuery('DepartmentID', value);
      this.getGQLItems('expenditureTypes', [
        'Name',
        'ID',
      ], query).then(res => {
        this.expenditureTypes = res.data.expenditureTypes.Items;
      });
    },

    link: function (item) {
      this.goToPage(`transactions/edit/payment/${item.ID}`);
    },

    handleOperationalDate: function (value) {
      localStorage.setItem('app.list.transactions.filters.operational_date', value);
    },

    filterOperationalDate: function (singleValue = true) {
      let value = null;
      if (singleValue) {
        value = [this.operationalDate, this.operationalDate];
      } else {
        value = this.operationalDate;
      }

      this.$nextTick(() => {
        this.$store.commit('filterListBy', {
          field: 'OperationalDate',
          value: value,
          type: 'date',
          filterInputName: 'default'
        });
      });
    },
  },

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('inventoryVendors', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.inventoryVendors = res.data.inventoryVendors.Items;
    });

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });

    let sortUsersQuery = this.getSortQuery('FirstName', 'ASC');
    this.getGQLItems('users', [
      'FirstName',
      'LastName',
      'ID',
    ], sortUsersQuery).then(res => {
      res.data.users.Items.forEach(i => {
        i.Name = `${i.FirstName} ${i.LastName}`;
      });
      this.users = res.data.users.Items;
    });

    this.getGQLItems('departments', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.departments = res.data.departments.Items;
    });

    let query = this.getSingleQuery('ShowInExpenditures', true);
    this.getGQLItems('paymentTypes', [
      'Name',
      'TransactionDirection',
      'ID',
    ], query).then(res => {
      let items = [];
      res.data.paymentTypes.Items.forEach(i => {
        if (this.toBoolean(i.TransactionDirection)) {
          items.push(i);
        }
      })
      this.paymentTypes = items;
    });
  },

  computed: {
    params: function () {
      if (this.getRole === 'manager' || this.getRole === 'marketing') {
        return `{
              Eq: {
                Name: "LocationID"
                Value: "${this.getUser.LocationID}"
              }
            }`;
      }
      return ``;
    }
  }
}
</script>