<template>
  <section class="app-list-section">
    <v-card outlined flat class="app-list-section-header">
      <v-app-bar flat>
        <div class="text-h7 text-lg-h5">
          {{ title }}
        </div>

        <v-spacer></v-spacer>
        <ListActions @action="handleAction"
                     @focusSearch="focusSearch"
                     @delete="deleteSelected"
                     ref="listActions"
                     :add-link="addLink"
                     :selectable="selectable"
                     :delete-by="deleteBy"
                     :export-query-type="exportQueryType"
                     :options="options"
                     :search-by="searchBy"
                     :hide-filters="hideFilters">
          <slot name="actions"></slot>
        </ListActions>
      </v-app-bar>
    </v-card>

    <div v-show="actions.filters">
      <v-layout wrap
                :class="`${hideFilters ? '' : 'pt-3'} app-list-row-filters ${noFiltersBottomPadding || hideFilters ? 'pb-0' : 'pb-3'} ${denseFilters ? 'dense-filters' : ''}`">
        <v-col v-if="searchBy" :class="{'pt-0': noSearchPadding, 'pb-0': noSearchBottomPadding}" cols="12"
               :md="largeSearch ? 6 : 3" :lg="largeSearch ? 5 : 3" :xl="largeSearch ? 2 : 1">
          <v-text-field filled
                        outlined
                        ref="search"
                        :dense="actions.dense"
                        v-on:click:clear="search(null)"
                        @change="search"
                        label="Caută"
                        single-line
                        clearable
                        hide-details
                        prepend-inner-icon="search"/>
        </v-col>
        <slot name="filters"></slot>
      </v-layout>
    </div>

    <v-card outlined flat class="app-list-section-content">
      <ListScrollbar/>
      <v-simple-table :height="listHeight" fixed-header :dense="actions.dense" :class="{
      'app-list': true,
      'selectable': actions.selectMode
    }">
        <template v-slot:default>
          <thead>
          <tr>
            <th width="40" v-if="actions.selectMode">
              <v-checkbox v-if="!singleSelect"
                          v-model="actions.selectAll"
                          @click="selectAll"
                          hide-details
                          class="mt-0 mb-0"/>
            </th>
            <slot name="head"></slot>
          </tr>
          <slot name="bottom-head"></slot>
          </thead>
          <tbody>
          <tr :class="[
                isSelected(item) ? 'is-selected' : '',
                !rowClass || rowClass(item),
                rowClick ? 'cursor-pointer' : ''
              ]"
              @click="select(item)"
              :key="item[itemKey]"
              v-for="(item, i) in items">

            <td v-if="actions.selectMode">
              <v-checkbox :input-value="isSelected(item)" hide-details class="mt-0 mb-0"/>
            </td>

            <slot name="row" :index="i" :item="item"></slot>

          </tr>
          <tr v-if="items.length === 0">
            <td class="no-data" colspan="10">Nu au fost găsite date</td>
          </tr>
          </tbody>
          <tfoot v-if="rerender">
          <slot :items="items" name="footer"></slot>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card>


    <v-layout :justify-end="!isMobile" :justify-center="isMobile" align-center wrap class="app-list-pagination">
      Date per pagină:
      <div class="per-page">
        <v-select class="pt-0 mt-0"
                  :items="[10, 20, 50, 100, 500, 1000, {text: 'All', value: -1}]"
                  @change="handleListChange('nrOfItems')"
                  v-model="list.nrOfItems"
                  hide-details/>
      </div>
      1-{{ list.nrOfItems < 0 ? 'All' : list.nrOfItems }} din {{ list.total }}
      <v-pagination :total-visible="10"
                    @input="handleListChange"
                    v-model="list.page"
                    :length="list.lastPage"/>
    </v-layout>

  </section>
</template>
<script>
import ListActions from './ListActions';
import ListScrollbar from './ListScrollbar';
import gql from "graphql-tag";

export default {

  components: {
    ListActions,
    ListScrollbar,
  },

  data() {
    return {
      items: this.fakeItems,
      rerender: true,

      actions: {
        filters: false,
        dense: false,
        selectMode: false,
        selectAll: false,
      },

      selected: [],

      list: {
        page: 1,
        nrOfItems: this.itemsPerPage,
        lastPage: 1,
        total: 0,
        search: ``,
      },
      sort: {},
      query: [],
    }
  },

  watch: {
    getListSort: {
      handler(sort) {
        this.sort = sort;
        this.get();
      },

      deep: true,
    },

    additionalQueryParams: {
      deep: true,

      handler() {
        this.get();
      }
    },

    getListFilters: {
      handler(filters) {
        this.$store.commit('setListEncodedFilters', null);
        filters = this.clone(filters);
        for (let i = 0; i < filters.length; i++) {
          let f = filters[i];
          if (
              typeof f.value === 'string' && !f.value
              ||
              !f.value
              ||
              typeof f.value === 'object' && f.value && f.value.length === 0
          ) {
            filters.splice(i, 1);
            i--;
          }
        }
        this.query = filters;
        let encodedFilters = JSON.stringify(this.query);
        if (JSON.stringify(this.getListFilterQueryValue) !== encodedFilters) {
          this.$router.push({
            query: {
              filters: encodedFilters
            }
          });
        }

        this.$store.commit('setListEncodedFilters', encodedFilters);

        this.get();
      },

      deep: true,
    },
  },

  props: {
    title: String,
    searchBy: Array,
    options: Array,
    additionalQueryFields: Array,
    hideFilters: Boolean,
    noStartRequest: Boolean,
    showFilters: Boolean,
    rowClick: Function,
    rowClass: Function,
    dense: Boolean,
    denseFilters: Boolean,
    selectable: Boolean,
    noSearchBottomPadding: Boolean,
    noSearchPadding: Boolean,
    noFiltersBottomPadding: Boolean,
    largeSearch: Boolean,
    singleSelect: Boolean,
    addLink: String,
    deleteBy: String,
    itemsPerPage: Number,
    itemKey: {
      default() {
        return 'ID'
      }
    },

    height: {
      default() {
        return 220
      }
    },

    fakeItems: {
      default() {
        return []
      }
    },

    defaultInputName: {
      default() {
        return "input";
      }
    },

    additionalQueryParams: {
      default() {
        return {}
      }
    },

    queryType: String,
    exportQueryType: String,
    queryParams: String,
    queryFields: {
      default() {
        return []
      }
    },
    exportFileType: {
      default() {
        return 'Excel'
      }
    },
    deleteMutationType: String,
    deleteMutationField: String,
    deleteMutationParams: {
      default() {
        return ''
      }
    },
  },

  created() {
    if (!this.itemsPerPage) {
      this.list.nrOfItems = this.getNrOfItems;
    }

    this.sort = this.getListSort;
  },

  mounted() {
    if (this.$route.query.page) {
      this.list.page = parseInt(this.$route.query.page);
    }
    if (this.$route.query.nrOfItems) {
      this.list.nrOfItems = parseInt(this.$route.query.nrOfItems);
    }

    if (this.showFilters) {
      this.$refs.listActions.handleFilters(true);
    }

    if (this.dense) {
      this.$refs.listActions.handleDense(true);
    }

    if (this.getListFilterQueryValue.length > 0) {
      this.query = this.getListFilterQueryValue;
      this.query.forEach(field => {
        this.$store.commit('filterListBy', field);
      });
      this.actions.filters = true;
    }

    if (!this.noStartRequest) {
      if (this.getListFilters.length === 0 && this.query.length === 0) {
        this.get();
      }
    }
    this.$store.commit('setList', this);
  },

  methods: {
    get: function () {
      if (Object.keys(this.query).length === 0) {
        this.$store.commit('setListEncodedFilters', null);
      }

      if (this.queryFields.length === 0) {
        return false;
      }

      this.togglePreloader();

      let {
        functionParamsImploded,
        queryFields,
        additionalQueryFields
      } = this.getListQueryParams;

      this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query {
          ${this.queryType} (${functionParamsImploded}) {
            ${additionalQueryFields}
            Counters {
                TotalItems
                TotalPages
            }
            Items {
                ${queryFields}
            }
          }
        }`
      }).then(res => {
        this.$emit('response', res.data[this.queryType]);
        if (res.data[this.queryType]) {
          this.items = [];
          this.rerender = false;
          this.$nextTick(() => {
            this.items = res.data[this.queryType].Items || [];
            this.list.total = res.data[this.queryType].Counters.TotalItems;
            this.list.lastPage = res.data[this.queryType].Counters.TotalPages;
            this.rerender = true;
          });
        }
        this.togglePreloader(false);
      }).catch(res => {
        this.notify('Eroare tehnică', 'error');
        this.togglePreloader(false);
      });
    },

    exportFile: function () {
      let {functionParamsImploded} = this.getListQueryParams;

      return this.$apollo.query({
        query: gql`query {
            ${this.exportQueryType} (${functionParamsImploded}) {
              Export {
                ${this.exportFileType} {
                  ID
                  Name
                }
              }
            }
        }`
      }).then(res => {
        if (res.data[this.exportQueryType]) {
          let fileID = res.data[this.exportQueryType].Export[this.exportFileType].ID;
          this.notify('Descărcare fișier..');
          setTimeout(() => {
            window.open(`/api/downloadFileByUUID/${fileID}`);
          }, 500);
        }
      }).catch(res => {
        this.notify('Eroare tehnică, nu s-a putut descărca fișiser-ul', 'error');
      });
    },

    deleteSelected: function () {
      if (this.selected.length === 0) {
        return false;
      }
      this.togglePreloader();
      let promises = [];
      let deleteMutationParams = `${this.deleteMutationParams ? ',' : ''}${this.deleteMutationParams}`;
      this.selected.forEach(item => {
        let payload = `["${item[this.deleteBy]}"]`;
        if (this.singleSelect) {
          payload = `"${item[this.deleteBy]}"`;
        }
        let promise = this.$apollo.mutate({
          mutation: gql`mutation {
              ${this.deleteMutationType}(${this.deleteMutationField}: ${payload}${deleteMutationParams})
          }
          `
        });
        promises.push(promise);
      });

      Promise.all(promises).then(res => {
        this.actions.selectMode = false;
        this.notify('Datele au fost ștese cu succes');
        this.get();
        this.togglePreloader(false);
      }).catch(res => {
        this.notify('Eroare tehnică', 'error');
        this.togglePreloader(false);
      });
    },

    selectAll: function () {
      if (this.selected.length === this.items.length) {
        this.selected = [];
      } else {
        this.selected = [...this.items];
      }
      this.$emit('select', {
        selected: this.selected,
        all: true
      });
    },

    handleListChange: function (action = '') {
      if (action === 'nrOfItems') {
        this.list.page = 1;
        localStorage.setItem('app.list.nrOfItems', this.list.nrOfItems);
      }
      let params = {
        page: this.list.page,
        nrOfItems: this.list.nrOfItems,
      };
      this.$router.push({
        query: params,
      });

      this.get();
    },

    select: function (item) {
      if (!this.actions.selectMode) {
        if (this.rowClick) {
          this.rowClick(item);
        }
      }

      if (!this.actions.selectMode) {
        return false;
      }

      let index = this.selected.findIndex(i => i[this.itemKey] === item[this.itemKey]);
      let checked = false;
      if (index >= 0) {
        this.$delete(this.selected, index);
      } else {
        this.selected.push(item);
        checked = true;
      }

      if (this.singleSelect) {
        if (checked) {
          this.selected = [item];
        } else {
          this.selected = [];
        }
      }

      this.$emit('select', {
        item: item,
        checked: checked,
        selected: this.selected,
        all: false
      });
    },

    search: function (value = null) {
      if (!this.searchBy) {
        return false;
      }

      if (!value) {
        this.list.search = ``;
        this.get();
        return false;
      }

      let searchFilter = ``;
      let conditions = ``;
      this.searchBy.forEach(field => {
        conditions += `
          {
            Or: true,
            Contains: {
              Name: "${field}",
              Value: "${value}",
              CaseInsensitive: true,
            }
          },
        `;
      });

      searchFilter += `
        {
          Conditions: [
            ${conditions}
          ]
        }
      `;

      this.list.search = searchFilter;
      this.get();
    },

    handleAction: function (action) {
      this.actions = {...this.actions, ...action};
      this.$emit('actions', this.actions);
    },

    focusSearch: function () {
      this.$nextTick(() => {
        if (this.actions.filters) {
          this.$refs['search'].focus();
        }
      });
    },

    isSelected: function (item) {
      let i = this.selected.find(i => i[this.itemKey] === item[this.itemKey]);
      if (i) {
        return true;
      }
      return false;
    },

  },

  computed: {
    getListQueryParams: function () {
      let conditions = ``;
      this.query.forEach(filter => {
        if (filter.filterInputName === 'default') {
          if (filter.type === 'select') {
            conditions += `
            {
              Eq: {
                Name: "${filter.field}"
                Value: "${filter.value}"
              }
            }
          `;
          } else if (filter.type === 'date') {
            conditions += `
            {
              Between: {
                Name: "${filter.field}"
                Value1: "${filter.value[0]}"
                Value2: "${filter.value[1]}"
              }
            }
          `;
          } else if (filter.type === 'multiselect') {
            let values = this.getFilterGQLMultipleValues(filter.value);
            conditions += `
            {
              In: {
                Name: "${filter.field}"
                Value: [${values}]
              }
            }
          `;
          } else if (filter.type === 'text') {
            conditions += `
            {
              Contains: {
                Name: "${filter.field}"
                Value: "${filter.value}"
                CaseInsensitive: true
              }
            }
          `;
          }
        } else {
          // todo: callback function
        }
      });
      if (this.queryParams) {
        conditions += this.queryParams;
      }

      let groupCondition = ``;

      if (this.list.search) {
        groupCondition += this.list.search;
      }


      let rootConditions = `,
      RootConditions: {
          Conditions: [
            ${conditions}
          ],
          Groups: [
            ${groupCondition}
          ]
      }`;

      let order = ``;

      if (this.sort.field && this.sort.direction) {
        order = `, Order: {
            FieldName: "${this.sort.field}",
            Direction: "${this.sort.direction}"
          }`;
      }


      let inputFilters = `${this.defaultInputName}: {
            PageNr: ${this.list.page},
            NrOfItems: ${this.list.nrOfItems}
            ${order}
            ${rootConditions}
          }`,
          queryFields = this.queryFields.join(' ');

      let functionParams = [];
      functionParams.push(inputFilters)
      if (this.additionalQueryParams && typeof this.additionalQueryParams !== 'string') {
        Object.keys(this.additionalQueryParams).forEach(input => {
          let inputFields = this.additionalQueryParams[input];
          let additionalInputFields = this.createGQLQueryFromObject(inputFields, true);

          let data = ``;
          this.query.forEach(filter => {
            if (filter.filterInputName === input) {
              if (filter.type === 'date') {
                data += `${filter.field}: ["${filter.value[0]}", "${filter.value[1]}"]`;
              } else if (filter.type === 'text' || filter.type === 'select') {
                data += `${filter.field}: "${filter.value}"`;
              } else if (filter.type === 'multiselect') {
                let values = this.getFilterGQLMultipleValues(filter.value);
                data += `${filter.field}: [${values}]`;
              }
            }
          });
          functionParams.push(`${input}: { ${additionalInputFields} ${data} }`);
        })
      } else {
        functionParams.push(this.additionalQueryParams);
      }

      let functionParamsImploded = ``;
      if (functionParams.length > 0) {
        functionParamsImploded = functionParams.join(",")
      }

      let additionalQueryFields = ``;
      if (this.additionalQueryFields) {
        additionalQueryFields = this.additionalQueryFields.join(',');
      }

      return {
        functionParamsImploded: functionParamsImploded,
        queryFields: queryFields,
        additionalQueryFields: additionalQueryFields,
      }
    },

    getListSort: function () {
      return this.$store.getters.getListSort;
    },

    getListFilters: function () {
      return this.$store.getters.getListFilters;
    },

    listHeight: function () {
      let initialFiltersHeight = 110;
      let four = this.searchBy && this.searchBy.length > 0 ? 1 : 0;
      let l = 4;
      if (this.$slots.filters && $(window).width() < 1904) {
        this.$slots.filters.forEach(f => {
          four++;
          if (four === l) {
            if (this.actions.dense) {
              initialFiltersHeight += 10;
            } else {
              initialFiltersHeight += 30;
            }
            four = 0;
          }
        });
      }
      if (this.actions.filters) {
        return `calc(100vh - ${this.height + initialFiltersHeight}px)`;
      }
      return `calc(100vh - ${this.height}px)`;
    },

    getListFilterQueryValue: function () {
      if (this.$route.query.filters) {
        return JSON.parse(this.$route.query.filters);
      }
      return [];
    },

    getNrOfItems: function () {
      if (!localStorage.getItem('app.list.nrOfItems')) {
        return 20;
      }

      return parseFloat(localStorage.getItem('app.list.nrOfItems'));
    }
  }

}
</script>