window.axios = require('axios');

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error.response);
});

// const { fetch: originalFetch } = window;
// window.fetch = async (...args) => {
//     let resource = args[0];
//     let config = args[1];
//     let response = await originalFetch(resource, config);
//
//     // response interceptor
//     const json = () =>
//         response
//             .clone()
//             .json()
//             .then((data) => ({ ...data, title: `Intercepted: ${data.title}` }))
//             .catch(res => {
//                 console.log('resError', res);
//             });
//
//     response.json = json;
//     console.log('hello there');
//     return response;
// };