<template>
  <app-form :query-fields="[
              'Name',
              'ID',
              'VendorID',
              'VendorModelID',
            ]"
            @get="get"
            v-model="item"
            delete-mutation-type="deleteCabinet"
            route-param="id"
            delete-field="cabinetIDs"
            mutation-type="saveCabinet"
            query-type="cabinet"
            title="Cabinet">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="item.VendorID" :items="vendors"
                            item-text="Name"
                            item-value="ID"
                            @change="getVendorModels"
                            :rules="[getMainRules.required]"
                            outlined label="Producător"/>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="item.VendorModelID" :items="vendorModels"
                            item-text="Name"
                            item-value="ID"
                            :rules="[getMainRules.required]"
                            outlined label="Model"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Nume"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
      vendors: [],
      vendorModels: [],
    }
  },

  mounted() {
    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ]).then(res => {
      this.vendors = res.data.vendors.Items;
    });

  },

  methods: {
    get: function() {
      this.getVendorModels(this.item.VendorID);
    },

    getVendorModels: function (value) {
      let query = this.getSingleQuery('VendorID', value);
      this.getGQLItems('vendorModels', [
        'Name',
        'ID',
      ], query).then(res => {
        this.vendorModels = res.data.vendorModels.Items;
      });
    }
  }
}
</script>