<template>
  <v-flex>
    <v-dialog max-width="450" v-model="modal">
      <v-card>
        <v-card-title>
          {{ selectedFile.OriginalName }}
        </v-card-title>
        <v-card-text>
          Mărime: {{ round(getMBSize) }}MB
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <!--          <v-btn depressed @click="modal = false">-->
          <!--            Închide-->
          <!--          </v-btn>-->
          <v-btn color="primary" depressed @click="downloadFile">
            <v-icon left>download</v-icon>
            Descarcă
          </v-btn>
          <v-btn color="success" depressed @click="viewFile">
            <v-icon left>visibility</v-icon>
            Deschide
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-file-input
        show-size
        :outlined="outlined"
        :solo="solo"
        v-model="files"
        :dense="dense"
        :multiple="multiple"
        :disabled="disabled || disabledAdd"
        :accept="accept"
        :rules="rules"
        :label="label"
    />
    <v-layout wrap class="mb-5">
      <v-chip
          close
          @click="openFile(item)"
          @click:close="deleteFile(item.ID, i)"
          class="mr-2 mt-1"
          :key="item.ID" v-for="(item, i) in items"
          pill>
        <template v-if="item.Extension === 'pdf'">
          <v-icon left>picture_as_pdf</v-icon>
        </template>
        <template v-else>
          <v-avatar left>
            <v-img :src="`${getUrl}storageDownload/${item.ID}`"></v-img>
          </v-avatar>
        </template>
        <v-flex style="max-width: 100px; overflow: hidden; text-overflow: ellipsis">
          {{ item.Name }}
        </v-flex>
      </v-chip>
    </v-layout>
  </v-flex>
</template>
<script>
export default {

  data() {
    return {
      files: this.value,

      modal: false,
      selectedFile: {},
    }
  },

  props: {
    outlined: Boolean,
    disabled: Boolean,
    disabledAdd: Boolean,
    disabledDelete: Boolean,
    solo: Boolean,
    dense: Boolean,
    multiple: Boolean,
    label: String,
    accept: String,
    rules: Array,
    value: Array,
    items: {
      default() {
        return []
      }
    },
  },

  methods: {
    openFile: function (file) {
      this.selectedFile = file;
      this.modal = true;
    },

    downloadFile: function () {
      window.open(`${this.getUrl}storageDownload/${this.selectedFile.ID}`, '_blank');
      this.modal = false;
    },

    viewFile: function () {
      window.open(`${this.getUrl}storageView/${this.selectedFile.ID}`, '_blank');
    },

    deleteFile: function (ID, i) {
      if (this.disabled || this.disabledDelete) {
        return false;
      }

      let confirmation = confirm('Ești sigur?');
      if (!confirmation) {
        return false;
      }
      axios.get(`${this.getUrl}attachmentDelete/${ID}`).then(res => {
        this.$delete(this.items, i);
      });
    }
  },

  watch: {
    files: function () {
      this.$emit('input', this.files);
    },

    value: function () {
      this.files = this.value;
    }
  },

  computed: {
    getUrl: function () {
      return process.env.VUE_APP_PROD_GRAPHQL_PRIVATE_HOST;
    },

    getMBSize: function () {
      if (!this.selectedFile.Size) {
        return 0;
      }
      return this.selectedFile.Size / 1000000;
    }
  }

}
</script>