var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list',{attrs:{"title":"Locații","show-filters":"","search-by":['Name', 'NewtonName', 'Address'],"query-fields":[
              'ID',
              'Name',
              'NewtonName',
              'Address',
              'CreatedAt'
          ],"delete-mutation-type":"deleteLocation","delete-mutation-field":"locationIDs","export-query-type":"locationsExport","delete-by":_vm.getRole !== 'operations' ? 'ID' : null,"query-type":"locations","add-link":"locations/add"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name"}},[_vm._v("Nume")]),_c('lth',{attrs:{"sort-by":"NewtonName"}},[_vm._v("Newton Nume")]),_c('lth',{attrs:{"sort-by":"Address"}},[_vm._v("Adresă")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Data creării")])]},proxy:true},{key:"row",fn:function({item}){return [_c('ltd',{attrs:{"link":`locations/edit/${item.ID}`}},[_vm._v(_vm._s(item.Name))]),_c('ltd',[_vm._v(_vm._s(item.NewtonName))]),_c('ltd',[_vm._v(_vm._s(item.Address))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }