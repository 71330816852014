<template>
  <div class="d-flex flex-column">
    <v-app-bar class="v-bar--underline"
        :color="!getTheme ? 'darken-2' : ''"
        elevation="0">

      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Cashpot</v-toolbar-title>

      <v-spacer/>
      <v-btn @click="setTheme" icon>
        <v-icon>{{ getTheme ? 'wb_sunny' : 'dark_mode' }}</v-icon>
      </v-btn>

      <v-btn @click="signOut" icon>
        <v-icon>logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
        width="300"
        v-model="drawer"
        fixed>
      <Profile/>
      <List :items="items"/>
    </v-navigation-drawer>
    <v-container>
      <router-view></router-view>
    </v-container>
  </div>
</template>
<script>
import List from '@/components/app/menu/List';
import Profile from "@/components/app/menu/Profile";

export default {
  components: {
    Profile,
    List
  },

  data() {
    return {
      drawer: false,

      isAuthorizedVerification: null,

      items: [
        {title: 'Tranzacții', link: '/'},
        {title: 'Tranzacții - Registru de Casă', link: 'cash-register-payments'},
        {
          title: 'Nomenclator inventar',
          icon: 'inventory_2',
          items: [
            {title: 'Inventar', link: 'inventory/inventories'},
            {title: 'Tipuri', link: 'inventory/types'},
            {title: 'Modele', link: 'inventory/models'},
            {title: 'Furnizori', link: 'inventory/providers'},
          ]
        },
        {
          title: 'Rapoarte',
          icon: 'tab',
          items: [
            {title: 'Locații', link: 'reports/locations'},
            {title: 'Stații', link: 'reports/stations'},
            {title: 'Cheltuieli', link: 'reports/expenditures'},
            {title: 'Registru de casa', link: 'cash-register'},
            {title: 'Producători', link: 'reports/production'},
          ]
        },
      ]
    }
  },

  mounted() {
    this.isAuthorizedVerification = setInterval(() => {
      if (!this.$cookies.get('device_session')) {
        this.signOut();
      }
    }, 1500);
  },

  beforeUnmount() {
    window.clearInterval(this.isAuthorizedVerification);
  }
}
</script>