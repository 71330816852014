<template>
  <app-list title="Inventar"
            :search-by="['SerialNr', 'Details']"
            show-filters
            :query-fields="[
                'ID',
                'SerialNr',
                'Color',
                'InventoryNr',
                'Status',
                'Details',
                'InventoryVendor {Name}',
                'InventoryVendorID',
                'InventoryModel {Name}',
                'InventoryModelID',
                'InventoryType {Name}',
                'InventoryTypeID',
                'Location {Name}',
                'LocationID',
                'CreatedAt',
                'Attachments {ID}',
                'UpdatedAt',
                'UpdatedBy {FullName}',
                'SoldAt',
            ]"
            :query-params="getParams"
            delete-mutation-type="deleteInventory"
            delete-mutation-field="ids"
            export-query-type="inventoryExport"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="inventoryy"
            add-link="inventories/add">
    <template #head>
      <lth sort-by="InventoryNr">Nr. Inventar</lth>
      <lth sort-by="InventoryType.Name">Tip</lth>
      <lth sort-by="InventoryModel.Name">Model</lth>
      <lth sort-by="SerialNr">SerialNr</lth>
      <lth width="50"></lth>
      <lth sort-by="Location.Name" v-if="getRole === 'admin' || getRole === 'operations'">Locație</lth>
      <lth sort-by="Color">Culoare / Sigilii</lth>
      <lth sort-by="InventoryVendor.Name">Furnizor</lth>
      <lth sort-by="Details">Detalii</lth>
      <lth sort-by="CreatedAt">Data modificării</lth>
      <lth sort-by="UpdatedBy.FirstName">Modificat de</lth>
      <lth sort-by="SoldAt">Vândut la</lth>
    </template>
    <template #filters>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf field="InventoryTypeID" multiselect :items="inventoryTypes" label="Tip" @change="inventoryChange"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf :disabled="typeModels.length === 0" field="InventoryModelID" multiselect :items="typeModels" label="Model"/>
      </v-col>
      <v-col v-if="getRole === 'admin' || getRole === 'operations'" cols="12" md="3" lg="3" xl="2">
        <lf field="LocationID" multiselect :items="locations" label="Locație"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf field="InventoryVendorID" multiselect :items="inventoryVendors" label="Furnizor"/>
      </v-col>
      <v-col cols="12" md="3" lg="3" xl="2">
        <lf field="Status"
            multiselect
            item-text="text"
            item-value="value"
            :items="statuses" label="Statut"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd>{{ item.InventoryNr }}</ltd>
      <ltd>
        {{ item.InventoryType ? item.InventoryType.Name : '' }}
      </ltd>
      <ltd>
        {{ item.InventoryModel ? item.InventoryModel.Name : '' }}
      </ltd>
      <ltd :link="`inventory/inventories/edit/${item.ID}`">{{ item.SerialNr }}</ltd>
      <ltd>
        <template v-if="item.Attachments.length > 0">
          <v-icon>attach_file</v-icon>
        </template>
      </ltd>
      <ltd v-if="getRole === 'admin' || getRole === 'operations'">{{ item.Location ? item.Location.Name : '' }}</ltd>
      <ltd>{{ item.Color }}</ltd>
      <ltd>{{ item.InventoryVendor ? item.InventoryVendor.Name : '' }}</ltd>
      <ltd>{{ item.Details }}</ltd>
      <ltd date>{{ item.UpdatedAt }}</ltd>
      <ltd>{{ item.UpdatedBy ? item.UpdatedBy.FullName : '' }}</ltd>
      <ltd date>{{ item.SoldAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  data() {
    return {
      inventoryVendors: [],
      inventoryTypes: [],
      typeModels: [],
      inventoryModels: [],
      locations: [],
      statuses: [
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Depozit',
          value: 'stored',
        },
        {
          text: 'Sold',
          value: 'sold',
        },
      ],
    }
  },

  mounted() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('inventoryVendors', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.inventoryVendors = res.data.inventoryVendors.Items;
    });

    this.getGQLItems('inventoryTypes', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.inventoryTypes = res.data.inventoryTypes.Items;
    });

    this.getGQLItems('inventoryModels', [
      'Name',
      'InventoryTypeID',
      'ID',
    ], sortQuery).then(res => {
      this.inventoryModels = res.data.inventoryModels.Items;
    });

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
    });

    this.$store.commit('filterListBy', {
      field: 'Status',
      value: ['active', 'stored'],
      type: 'multiselect',
      filterInputName: 'default'
    });
  },

  methods: {
    inventoryChange: function (ids) {
      let models = [];
      this.inventoryModels.forEach(i => {
        if (ids.includes(i.InventoryTypeID)) {
          models.push(i);
        }
      });
      this.typeModels = models;
    }
  },

  computed: {
    getParams: function () {
      if (this.getRole === 'manager') {
        return `{
              Eq: {
                Name: "LocationID"
                Value: "${this.getUser.LocationID}"
              }
            }`;
      }
      return ``;
    }
  }
}
</script>