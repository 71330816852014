var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-form',{attrs:{"query-fields":[
              'ID',
              'Name',
              'FiscalCode',
              'Address',
              'Phone',
          ],"delete-mutation-type":"deleteInventoryVendor","route-param":"id","error-handler":_vm.errorHandler,"delete-field":"ids","mutation-type":"saveInventoryVendor","query-type":"inventoryVendor","title":"Furnizor"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('app-form-section',[_c('app-form-section-block',{attrs:{"icon":"info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"Denumire"},model:{value:(_vm.item.Name),callback:function ($$v) {_vm.$set(_vm.item, "Name", $$v)},expression:"item.Name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"FiscalCode"},model:{value:(_vm.item.FiscalCode),callback:function ($$v) {_vm.$set(_vm.item, "FiscalCode", $$v)},expression:"item.FiscalCode"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"Address"},model:{value:(_vm.item.Address),callback:function ($$v) {_vm.$set(_vm.item, "Address", $$v)},expression:"item.Address"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","outlined":"","label":"Phone"},model:{value:(_vm.item.Phone),callback:function ($$v) {_vm.$set(_vm.item, "Phone", $$v)},expression:"item.Phone"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }