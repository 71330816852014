<template>
  <app-list title="Cabinete"
            :search-by="['Name']"
            show-filters
            :query-fields="[
                'ID',
                'Name',
                'NrOfStations',
                'CreatedAt'
            ]"
            delete-mutation-type="deleteCabinet"
            delete-mutation-field="cabinetIDs"
            export-query-type="cabinetsExport"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="cabinets"
            add-link="cabinets/add">
    <template #head>
      <lth sort-by="Name">Nume</lth>
      <lth sort-by="NrOfStations">NrOfStations</lth>
      <lth sort-by="CreatedAt">Data creării</lth>
    </template>
    <template #row="{item}">
      <ltd :link="`cabinets/edit/${item.ID}`">{{ item.Name }}</ltd>
      <ltd>{{ item.NrOfStations }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  created() {
    this.$store.commit('setListSort', {
      direction: 'asc',
      field: 'Name'
    });
  }
}
</script>