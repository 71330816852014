<template>
  <app-form :query-fields="[
              'Name',
              'ID',
              'VendorID',
            ]"
            v-model="item"
            delete-mutation-type="deleteVendorModel"
            route-param="id"
            delete-field="vendorModelIDs"
            mutation-type="saveVendorModel"
            query-type="vendorModel"
            title="Model">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12">
            <v-autocomplete :items="vendors"
                            item-text="Name"
                            item-value="ID"
                            v-model="item.VendorID"
                            :rules="[getMainRules.required]" outlined label="Producător"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Nume"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
      vendors: []
    }
  },

  mounted() {
    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ]).then(res => {
      this.vendors = res.data.vendors.Items;
    });
  }
}
</script>