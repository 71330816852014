<template>
  <div>
    <v-app-bar dark class="app-bar" clipped-left app elevate-on-scroll>
      <TopBar/>
    </v-app-bar>
    <v-navigation-drawer app
                         width="300"
                         height="calc(100% - 110px)"
                         class="app-navigation"
                         clipped
                         permanent
                         :mini-variant.sync="mini">
      <Navigation :mini.sync="mini" :items="items"/>
    </v-navigation-drawer>
    <v-main>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </v-main>
  </div>
</template>
<script>
import Navigation from "@/components/app/menu/Navigation";
import TopBar from "@/components/app/bar/TopBar";

export default {

  components: {
    TopBar,
    Navigation,
  },

  data: () => ({
    mini: false,

    isAuthorizedVerification: null,

    items: [
      {title: 'Cheltuieli', link: '/'},
      {title: 'Inventar', link: 'inventory/inventories'},
    ]
  }),

  mounted() {
    this.isAuthorizedVerification = setInterval(() => {
      if (!this.$cookies.get('device_session')) {
        this.signOut();
      }
    }, 1500);
  },

  beforeUnmount() {
    window.clearInterval(this.isAuthorizedVerification);
  }
}
</script>
