import Vue from 'vue'
import App from './App.vue'
import store from './plugins/store/store'
import vuetify from './plugins/vuetify/vuetify'
import mixin from "@/mixins/app";
import './assets/css/styles.scss';
import cookies from 'vue-cookies';
import AppPageHeader from './components/page/Header';
import AppDatepicker from './components/app/fields/Date/Date';
import AppFile from './components/app/fields/File';
import AppYearPicker from './components/app/fields/Year';
import AppRangeDatepicker from './components/page/list/components/Date';
import AppList from './components/page/list/List';
import AppListTh from './components/page/list/ListTh';
import AppListTd from './components/page/list/ListTd';
import AppListFilter from './components/page/list/ListFilter';
import AppForm from './components/page/form/Form';
import AppFormSection from './components/page/form/Section';
import AppFormSectionBlock from './components/page/form/Block';
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import VueApollo from 'vue-apollo'
import {a, b} from "@/plugins/apollo";
import '@/plugins/axios';
Vue.use(VueApollo);
import VueMoment from 'vue-moment';
import router from "@/plugins/router";


Sentry.init({
    Vue,
    dsn: "https://fa6835e31a1041dbbf34d3b31e099644@sentry.kyaxcorp.com/7",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [
                // "localhost",
                "162.55.131.81",
                // "89.28.9.208",
                /^\//
            ],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

let moment = require('moment-timezone');
moment.locale('ro');
moment.updateLocale('ro', {
    week: {
        dow: 0
    }
});
moment.tz.setDefault("Europe/Chisinau");
require('moment/locale/ro')
Vue.use(VueMoment, {
    moment
});

const apolloProvider = new VueApollo({
    defaultClient: b,
    clients: {
        a,
        b
    }
});

global.jQuery = require('jquery');
window.$ = global.jQuery;

Vue.component('app-page-header', AppPageHeader);
Vue.component('app-list', AppList);
Vue.component('app-datepicker', AppDatepicker);
Vue.component('app-file', AppFile);
Vue.component('app-year-picker', AppYearPicker);
Vue.component('app-range-picker', AppRangeDatepicker);
Vue.component('lth', AppListTh);
Vue.component('ltd', AppListTd);
Vue.component('lf', AppListFilter);
Vue.component('app-form', AppForm);
Vue.component('app-form-section', AppFormSection);
Vue.component('app-form-section-block', AppFormSectionBlock);
Vue.use(cookies);
Vue.mixin(mixin);
Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
    store,
    vuetify,
    router,
    apolloProvider,
    render: h => h(App)
}).$mount('#app')
