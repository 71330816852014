import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery';
import AdminWebMain from '../../views/view_modules/admin/app/web/Main';
import AdminMobMain from '../../views/view_modules/admin/app/mob/Main';

import ManagerWebMain from '../../views/view_modules/manager/app/web/Main';
import ManagerMobMain from '../../views/view_modules/manager/app/mob/Main';

import MarketingWebMain from '../../views/view_modules/marketing/app/web/Main';
import MarketingMobMain from '../../views/view_modules/marketing/app/mob/Main';

import AccountingWebMain from '../../views/view_modules/accounting/app/web/Main';
import AccountingMobMain from '../../views/view_modules/accounting/app/mob/Main';

import OperationalWebMain from '../../views/view_modules/operational/app/web/Main';
import OperationalMobMain from '../../views/view_modules/operational/app/mob/Main';

import FourOFour from '../../views/404/FourOFour';
import SignIn from '../../views/auth/SignIn';
import Dashboard from '../../views/view_modules/admin/dashboard/Index';
import Stats from '../../views/view_modules/admin/statistics/Index';
import Transactions from '../../views/view_modules/manager/transactions/Index';

import Cabinets from '../../views/view_modules/admin/cabinets/Index';
import CabinetsForm from '../../views/view_modules/admin/cabinets/Form';

import Expenditures from '../../views/view_modules/admin/expenditures/Index';
import ExpendituresForm from '../../views/view_modules/admin/expenditures/Form';

import Departments from '../../views/view_modules/admin/departments/Index';
import DepartmentsForm from '../../views/view_modules/admin/departments/Form';

import Games from '../../views/view_modules/admin/games/Index';
import GamesForm from '../../views/view_modules/admin/games/Form';

import Locations from '../../views/view_modules/admin/locations/Index';
import LocationsForm from '../../views/view_modules/admin/locations/Form';

import Stations from '../../views/view_modules/admin/stations/Index';
import StationsForm from '../../views/view_modules/admin/stations/Form';

import Vendors from '../../views/view_modules/admin/vendors/Index';
import VendorsForm from '../../views/view_modules/admin/vendors/Form';

import Models from '../../views/view_modules/admin/models/Index';
import ModelsForm from '../../views/view_modules/admin/models/Form';

import InventoryInventories from '../../views/view_modules/admin/inventory/inventories/Index';
import InventoryInventoriesForm from '../../views/view_modules/admin/inventory/inventories/Form';

import InventoryProviders from '../../views/view_modules/admin/inventory/providers/Index';
import InventoryProvidersForm from '../../views/view_modules/admin/inventory/providers/Form';

import InventoryTypes from '../../views/view_modules/admin/inventory/types/Index';
import InventoryTypesForm from '../../views/view_modules/admin/inventory/types/Form';

import InventoryModels from '../../views/view_modules/admin/inventory/models/Index';
import InventoryModelsForm from '../../views/view_modules/admin/inventory/models/Form';

import ReportsProduction from '../../views/view_modules/manager/productionReports/Index';
import CashRegisterIndex from '../../views/view_modules/manager/cashRegister/Index';
import CashRegisterPaymentsIndex from '../../views/view_modules/manager/cashRegisterPayments/Index';
import TransactionsForm from '../../views/view_modules/manager/transactions/Form';

import ReportsStations from '../../views/view_modules/admin/reports/stations/Index';
import ReportsLocations from '../../views/view_modules/admin/reports/locations/Index';
import ReportsExpenditures from '../../views/view_modules/admin/reports/expenditures/Index';
import ReportsExpendituresForm from '../../views/view_modules/admin/reports/expenditures/Form';

import Users from '../../views/view_modules/admin/users/Index';
import UsersForm from '../../views/view_modules/admin/users/Form';

import SimpleReports from '../../views/view_modules/admin/simple-reports/Index';
import SimpleReportsForm from '../../views/view_modules/admin/simple-reports/Form';

Vue.use(VueRouter);

let adminMainComponent = window.innerWidth < 600 ? AdminMobMain : AdminWebMain;
let managerMainComponent = window.innerWidth < 600 ? ManagerMobMain : ManagerWebMain;
let marketingMainComponent = window.innerWidth < 600 ? MarketingMobMain : MarketingWebMain;
let accountingMainComponent = window.innerWidth < 600 ? AccountingMobMain : AccountingWebMain;
let operationalMainComponent = window.innerWidth < 600 ? OperationalMobMain : OperationalWebMain;

let dashboardBC = [{text: 'Dashboard', href: '/',}];
let listBC = [...dashboardBC, {text: 'Nomenclator'}];

let mainRoutes = [

    {
        path: 'transactions', component: Transactions,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții', href: 'transactions'}]}
    },
    {
        path: 'transactions/:type/add', component: TransactionsForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții', href: 'transactions'}, {text: 'Adaugă'}]}
    },
    {
        path: 'transactions/edit/:type/:id', component: TransactionsForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții', href: 'transactions'}, {text: 'Editează'}]}
    },

    {
        path: 'statistics', component: Stats,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Statistică'}]}
    },

    {
        path: 'cabinets', component: Cabinets,
        meta: {breadcrumbs: [...listBC, {text: 'Cabinete'}]}
    },
    {
        path: 'cabinets/edit/:id', component: CabinetsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Cabinete', href: 'cabinets'}, {text: 'Editează cabinet'}]}
    },
    {
        path: 'cabinets/add', component: CabinetsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Cabinete', href: 'cabinets'}, {text: 'Adaugă cabinet'}]}
    },

    {
        path: 'expenditures', component: Expenditures,
        meta: {breadcrumbs: [...listBC, {text: 'Tip cheltuieli'}]}
    },
    {
        path: 'expenditures/edit/:id', component: ExpendituresForm,
        meta: {
            breadcrumbs: [...listBC, {
                text: 'Tip cheltuieli',
                href: 'expenditures'
            }, {text: 'Editează tip cheltuiala'}]
        }
    },
    {
        path: 'expenditures/add', component: ExpendituresForm,
        meta: {
            breadcrumbs: [...listBC, {
                text: 'Tip cheltuieli',
                href: 'expenditures'
            }, {text: 'Adaugă tip cheltuiala'}]
        }
    },

    {
        path: 'departments', component: Departments,
        meta: {breadcrumbs: [...listBC, {text: 'Departamente'}]}
    },
    {
        path: 'departments/edit/:id', component: DepartmentsForm,
        meta: {
            breadcrumbs: [...listBC, {
                text: 'Departamente',
                href: 'departments'
            }, {text: 'Editează departament'}]
        }
    },
    {
        path: 'departments/add', component: DepartmentsForm,
        meta: {
            breadcrumbs: [...listBC, {
                text: 'Departamente',
                href: 'departments'
            }, {text: 'Adaugă departament'}]
        }
    },

    {
        path: 'reports/cash-register', component: CashRegisterIndex,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte'}, {text: 'Registru de casă',}]}
    },
    {
        path: 'reports/cash-register-payments', component: CashRegisterPaymentsIndex,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte'}, {text: 'Tranzacții - Registru de Casă',}]}
    },

    {
        path: 'reports/locations', component: ReportsLocations,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte'}, {text: 'Locații'}]}
    },
    {
        path: 'reports/stations', component: ReportsStations,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Stații'}]}
    },
    {
        path: 'reports/expenditures', component: ReportsExpenditures,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Cheltuieli'}]}
    },
    {
        path: 'simple-reports', component: SimpleReports,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}]}
    },
    {
        path: 'simple-reports/edit/:id', component: SimpleReportsForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Editează raport simplu'}]}
    },


    {
        path: 'inventory/inventories', component: InventoryInventories,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar'}]}
    },
    {
        path: 'inventory/inventories/add', component: InventoryInventoriesForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Adaugă inventar'}]}
    },
    {
        path: 'inventory/inventories/edit/:id', component: InventoryInventoriesForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Editează inventar'}]}
    },

    {
        path: 'inventory/models', component: InventoryModels,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Modele'}]}
    },
    {
        path: 'inventory/models/add', component: InventoryModelsForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Modele', href: 'inventory/models'}, {text: 'Adaugă model'}]}
    },
    {
        path: 'inventory/models/edit/:id', component: InventoryModelsForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Modele', href: 'inventory/models'}, {text: 'Editează model'}]}
    },



    {
        path: 'inventory/types', component: InventoryTypes,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar',}, {text: 'Tipuri'}]}
    },
    {
        path: 'inventory/types/add', component: InventoryTypesForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Tipuri', href: 'inventory/types'}, {text: 'Adaugă tip'}]}
    },
    {
        path: 'inventory/types/edit/:id', component: InventoryTypesForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Tipuri', href: 'inventory/types'}, {text: 'Editează tip'}]}
    },



    {
        path: 'inventory/providers', component: InventoryProviders,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar',}, {text: 'Furnizori'}]}
    },
    {
        path: 'inventory/providers/add', component: InventoryProvidersForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Furnizori', href: 'inventory/providers'}, {text: 'Adaugă furnizor'}]}
    },
    {
        path: 'inventory/providers/edit/:id', component: InventoryProvidersForm,
        meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Furnizori', href: 'inventory/providers'}, {text: 'Editează furnizor'}]}
    },

    {
        path: 'locations', component: Locations,
        meta: {breadcrumbs: [...listBC, {text: 'Locații'}]}
    },
    {
        path: 'locations/edit/:id', component: LocationsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Locații', href: 'locations'}, {text: 'Editează locație'}]}
    },
    {
        path: 'locations/add', component: LocationsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Locații', href: 'locations'}, {text: 'Adaugă locație'}]}
    },


    {
        path: 'games', component: Games,
        meta: {breadcrumbs: [...listBC, {text: 'Jocuri'}]}
    },
    {
        path: 'games/edit/:id', component: GamesForm,
        meta: {breadcrumbs: [...listBC, {text: 'Jocuri', href: 'games'}, {text: 'Editează joc'}]}
    },
    {
        path: 'games/add', component: GamesForm,
        meta: {breadcrumbs: [...listBC, {text: 'Jocuri', href: 'games'}, {text: 'Adaugă joc'}]}
    },


    {
        path: 'stations', component: Stations,
        meta: {breadcrumbs: [...listBC, {text: 'Stații'}]}
    },
    {
        path: 'stations/edit/:id', component: StationsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Stații', href: 'stations'}, {text: 'Editează stație'}]}
    },
    {
        path: 'stations/add', component: StationsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Stații', href: 'stations'}, {text: 'Adaugă stație'}]}
    },

    {
        path: 'vendors', component: Vendors,
        meta: {breadcrumbs: [...listBC, {text: 'Producători'}]}
    },
    {
        path: 'vendors/edit/:id', component: VendorsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Producători', href: 'vendors'}, {text: 'Editează producător'}]}
    },
    {
        path: 'vendors/add', component: VendorsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Producători', href: 'vendors'}, {text: 'Adaugă producător'}]}
    },


    {
        path: 'models', component: Models,
        meta: {breadcrumbs: [...listBC, {text: 'Modele'}]}
    },
    {
        path: 'models/edit/:id', component: ModelsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Modele', href: 'models'}, {text: 'Editează model'}]}
    },
    {
        path: 'models/add', component: ModelsForm,
        meta: {breadcrumbs: [...listBC, {text: 'Modele', href: 'models'}, {text: 'Adaugă model'}]}
    },

];

const routes = [
    {path: '*', component: FourOFour},
    {path: '/login', component: SignIn, name: 'login'},
    {
        path: '/sys/user/admin',
        component: adminMainComponent,
        meta: {
            requiresAuth: true,
            requiresRole: 'admin'
        },
        children: [
            {
                path: '/', component: ReportsLocations,
                meta: {breadcrumbs: [...dashboardBC,]}
            },
            {
                path: 'reports/production', component: ReportsProduction,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Producători'}]}
            },
            {
                path: 'users', component: Users,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Utilizatori'}]}
            },
            {
                path: 'users/edit/:id', component: UsersForm,
                meta: {breadcrumbs: [...listBC, {text: 'Utilizatori', href: 'users'}, {text: 'Editează utilizator'}]}
            },
            {
                path: 'users/add', component: UsersForm,
                meta: {breadcrumbs: [...listBC, {text: 'Utilizatori', href: 'users'}, {text: 'Adaugă utilizator'}]}
            },
            ...mainRoutes,
        ]
    },
    {
        path: '/sys/user/manager',
        component: managerMainComponent,
        meta: {
            requiresAuth: true,
            requiresRole: 'manager'
        },
        children: [
            {
                    path: '/', component: Transactions,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții'}]}
            },
            {
                path: 'reports/expenditures/add', component: ReportsExpendituresForm,
                meta: {
                    breadcrumbs: [...dashboardBC, {
                        text: 'Cheltuieli',
                        href: 'expenditures'
                    }, {text: 'Adaugă cheltuială'}]
                }
            },

            {
                path: 'reports/production', component: ReportsProduction,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Producători'}]}
            },


            {
                path: 'inventory/providers', component: InventoryProviders,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar',}, {text: 'Furnizori'}]}
            },
            {
                path: 'inventory/providers/add', component: InventoryProvidersForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Furnizori', href: 'inventory/providers'}, {text: 'Adaugă furnizor'}]}
            },
            {
                path: 'inventory/providers/edit/:id', component: InventoryProvidersForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Furnizori', href: 'inventory/providers'}, {text: 'Editează furnizor'}]}
            },



            {
                path: 'inventory/inventories', component: InventoryInventories,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar'}]}
            },
            {
                path: 'inventory/inventories/add', component: InventoryInventoriesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Adaugă inventar'}]}
            },
            {
                path: 'inventory/inventories/edit/:id', component: InventoryInventoriesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Editează inventar'}]}
            },

            {
                path: 'inventory/models', component: InventoryModels,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Modele'}]}
            },
            {
                path: 'inventory/models/add', component: InventoryModelsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Modele', href: 'inventory/models'}, {text: 'Adaugă model'}]}
            },
            {
                path: 'inventory/models/edit/:id', component: InventoryModelsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Modele', href: 'inventory/models'}, {text: 'Editează model'}]}
            },



            {
                path: 'inventory/types', component: InventoryTypes,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar',}, {text: 'Tipuri'}]}
            },
            {
                path: 'inventory/types/add', component: InventoryTypesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Tipuri', href: 'inventory/types'}, {text: 'Adaugă tip'}]}
            },
            {
                path: 'inventory/types/edit/:id', component: InventoryTypesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Tipuri', href: 'inventory/types'}, {text: 'Editează tip'}]}
            },


            {
                path: 'cash-register', component: CashRegisterIndex,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Registru de casă',}]}
            },
            {
                path: 'cash-register-payments', component: CashRegisterPaymentsIndex,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții - Registru de Casă',}]}
            },
            {
                path: 'transactions/:type/add', component: TransactionsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții', href: 'transactions'}, {text: 'Adaugă'}]}
            },
            {
                path: 'transactions/edit/:type/:id', component: TransactionsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții', href: 'transactions'}, {text: 'Editează'}]}
            },


            {
                path: 'reports/locations', component: ReportsLocations,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte'}, {text: 'Locații'}]}
            },
            {
                path: 'reports/stations', component: ReportsStations,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Stații'}]}
            },
            {
                path: 'reports/expenditures', component: ReportsExpenditures,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Cheltuieli'}]}
            },
            {
                path: 'reports/expenditures/edit/:id', component: ReportsExpendituresForm,
                meta: {
                    breadcrumbs: [...dashboardBC, {
                        text: 'Cheltuieli',
                        href: 'expenditures'
                    }, {text: 'Editează cheltuială'}]
                }
            },

        ],
    },
    {
        path: '/sys/user/accounting',
        component: accountingMainComponent,
        meta: {
            requiresAuth: true,
            requiresRole: 'accounting'
        },
        children: [
            {
                path: '/', component: CashRegisterIndex,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Registru de casă',}]}
            },

            {
                path: 'inventory/providers', component: InventoryProviders,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar',}, {text: 'Furnizori'}]}
            },
            {
                path: 'inventory/providers/add', component: InventoryProvidersForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Furnizori', href: 'inventory/providers'}, {text: 'Adaugă furnizor'}]}
            },
            {
                path: 'inventory/providers/edit/:id', component: InventoryProvidersForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Furnizori', href: 'inventory/providers'}, {text: 'Editează furnizor'}]}
            },



            {
                path: 'transactions', component: Transactions,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții'}]}
            },
            {
                path: 'transactions/:type/add', component: TransactionsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții', href: 'transactions'}, {text: 'Adaugă'}]}
            },
            {
                path: 'transactions/edit/:type/:id', component: TransactionsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Tranzacții', href: 'transactions'}, {text: 'Editează'}]}
            },

            {
                path: 'expenditures', component: ReportsExpenditures,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Cheltuieli'}]}
            },
            {
                path: 'inventory/inventories', component: InventoryInventories,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}]}
            },
            {
                path: 'inventory/inventories/add', component: InventoryInventoriesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Adaugă inventar'}]}
            },
            {
                path: 'inventory/inventories/edit/:id', component: InventoryInventoriesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Editează inventar'}]}
            },
            {
                path: 'transactions/:type/add', component: TransactionsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Cheltuieli'}, {text: 'Adaugă'}]}
            },
        ]
    },
    {
        path: '/sys/user/marketing',
        component: marketingMainComponent,
        meta: {
            requiresAuth: true,
            requiresRole: 'marketing'
        },
        children: [
            {
                path: '/', component: ReportsExpenditures,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Cheltuieli'}]}
            },
            {
                path: 'inventory/inventories', component: InventoryInventories,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar'}]}
            },
            {
                path: 'inventory/inventories/add', component: InventoryInventoriesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Adaugă inventar'}]}
            },
            {
                path: 'inventory/inventories/edit/:id', component: InventoryInventoriesForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Inventar'}, {text: 'Inventar', href: 'inventory/inventories'}, {text: 'Editează inventar'}]}
            },
            {
                path: 'transactions/:type/add', component: TransactionsForm,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Cheltuieli'}, {text: 'Adaugă'}]}
            },
        ]
    },
    {
        path: '/sys/user/operations',
        component: operationalMainComponent,
        meta: {
            requiresAuth: true,
            requiresRole: 'operations'
        },
        children: [
            {
                path: '/', component: ReportsLocations,
                meta: {breadcrumbs: [...dashboardBC,]}
            },
            {
                path: 'reports/production', component: ReportsProduction,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Rapoarte',}, {text: 'Producători'}]}
            },

            ...mainRoutes,
        ]
    },
]

const router = new VueRouter({
    // base: (process.env.NODE_ENV === 'production') ? "/v1" : "/",
    mode: 'hash',
    routes
})

export default router
