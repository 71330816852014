<template>
  <component v-model="menu" :is="isMobile || modal ? 'Dialog' : 'Menu'">
    <template v-slot:activator="{ on }">
      <v-text-field
          :value="getDateValue"
          append-icon="mdi-calendar"
          readonly
          :hide-details="hideDetails"
          :outlined="outlined"
          :rules="rules"
          :label="label"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-if="!readonly"
        @change="$emit('change', $event)"
        v-model="date"
        @input="menu = false">
      <v-btn @click="date = null" text>
        Șterge
      </v-btn>
      <v-btn @click="date = $moment().format('YYYY-MM-DD')" text>
        Azi
      </v-btn>
      <v-spacer/>
      <v-btn text @click="menu = false">
        OK
      </v-btn>
    </v-date-picker>
  </component>
</template>
<script>
import Menu from './Menu';
import Dialog from './Dialog';

export default {

  components: {
    Menu,
    Dialog
  },

  data() {
    let value = this.formatDate(this.value);
    if (!this.value) {
      value = null;
    }
    return {
      date: value,
      menu: false,
    }
  },

  props: {
    value: String,
    label: String,
    outlined: Boolean,
    readonly: Boolean,
    modal: Boolean,
    hideDetails: Boolean,
    rules: Array,
  },

  watch: {
    date: function (date) {
      this.$emit('input', date);
    },

    value: function (value) {
      this.date = this.formatDate(value);
    }
  },

  computed: {
    getDateValue: function () {
      if (this.date) {
        return this.$moment(this.date).format('DD/MM/YYYY');
      }
      return '';
    },
  }
}
</script>