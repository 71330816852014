import { render, staticRenderFns } from "./Date.vue?vue&type=template&id=72a61e8f&scoped=true&"
import script from "./Date.vue?vue&type=script&lang=js&"
export * from "./Date.vue?vue&type=script&lang=js&"
import style0 from "./Date.vue?vue&type=style&index=0&id=72a61e8f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a61e8f",
  null
  
)

export default component.exports