<template>
  <td :class="[getClass]">
    <template v-if="link && getContent">
      <router-link exact :to="toPage(link)">
        <template v-if="getValue">
          {{ getValue }}
        </template>
        <template v-else>
          <slot></slot>
        </template>
      </router-link>
    </template>
    <template v-else>
      <template v-if="getValue">
        {{ getValue }}
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </template>
  </td>
</template>
<script>
export default {

  props: {
    link: String,
    boolean: Boolean,
    date: Boolean,
    hour: Boolean,
    amount: Boolean,
  },

  computed: {
    getValue: function () {
      if (this.date || this.hour) {
        let dateFormat = this.date ? 'DD-MM-YYYY' : '';
        let hourFormat = this.hour ? 'HH:mm' : '';
        let date = this.$moment(this.getContent).format(`${dateFormat} ${hourFormat}`);
        if (date !== 'Invalid date') {
          return date;
        }
        return null;
      } else if (this.boolean) {
        return this.toBoolean(this.getContent) ? 'Da' : 'Nu';
      } else if (this.amount) {
        return this.formatAmount(this.getContent);
      }

      return null;
    },

    getContent: function () {
      if (this.$slots.default) {
        return this.$slots.default[0].text;
      }
      return '';
    },

    getClass: function () {
      if (this.boolean) {
        if (this.toBoolean(this.getContent)) {
          return 'boolean true';
        } else {
          return 'boolean false';
        }
      }
      return '';
    }
  }

}
</script>