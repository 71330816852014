<template>
  <app-list title="Rapoarte locații"
            @actions="dense = $event.dense"
            show-filters
            export-query-type="locationsReportExport"
            default-input-name="filterInput"
            :search-by="['LocationName']"
            :query-params="getParams"
            :additional-query-params="params"
            :query-fields="[
                'LocationID',
                'LocationName',
                'NrOfStations',
                'TotalIn',
                'TotalOut',
                'TotalBet',
                'TotalWin',
                'TotalProfit',
                'TotalExpenditures',
            ]"
            query-type="locationsReport">
    <template #head>
      <lth sort-by="LocationName">Nume locație</lth>
      <lth sort-by="NrOfStations">NrOfStations</lth>
      <lth sort-by="TotalIn">TotalIn</lth>
      <lth sort-by="TotalOut">TotalOut</lth>
      <lth sort-by="TotalBet">TotalBet</lth>
      <lth sort-by="TotalProfit">TotalProfit</lth>
      <lth sort-by="TotalProfit">TotalExpenditures</lth>
    </template>
    <template #filters>
      <v-col lg="3" md="5" cols="12" xl="2">
        <app-range-picker :value="[params.customFilterInput.StartDateUtc, params.customFilterInput.EndDateUtc]"
                          :dense="dense" outlined label="Filtreaza după dată" @input="handleDateChange"/>
      </v-col>
    </template>
    <template #row="{item}">
      <ltd :link="`reports/stations?locationId=${item.LocationID}&date1=${params.customFilterInput.StartDateUtc}&date2=${params.customFilterInput.EndDateUtc}`">
        {{ item.LocationName }}</ltd>
      <ltd amount>{{ item.NrOfStations }}</ltd>
      <ltd amount>{{ item.TotalIn }}</ltd>
      <ltd amount>{{ item.TotalOut }}</ltd>
      <ltd amount>{{ item.TotalBet }}</ltd>
      <ltd amount>{{ item.TotalProfit }}</ltd>
      <ltd amount>{{ item.TotalExpenditures }}</ltd>
    </template>
    <template #footer="{items}">
      <tr>
        <lth></lth>
        <lth>{{ getTotal('NrOfStations', items) }}</lth>
        <lth>{{ getTotal('TotalIn', items) }}</lth>
        <lth>{{ getTotal('TotalOut', items) }}</lth>
        <lth>{{ getTotal('TotalBet', items) }}</lth>
        <lth>{{ getTotal('TotalProfit', items) }}</lth>
        <lth>{{ getTotal('TotalExpenditures', items) }}</lth>
      </tr>
    </template>
  </app-list>
</template>
<script>
export default {
  data() {
    let date1 = this.$moment().format('YYYY-MM-DD');
    let date2 = this.$moment().format('YYYY-MM-DD');
    if (this.$route.query.date1) {
      date1 = this.$route.query.date1;
      date2 = this.$route.query.date2;
    }

    return {
      dense: false,

      params: {
        customFilterInput: {
          StartDateUtc: date1,
          EndDateUtc: date2,
        }
      }
    }
  },

  created() {
    this.$store.commit('setListSort', {
      direction: 'asc',
      field: 'LocationName'
    });
  },

  methods: {
    handleDateChange: function (val) {
      this.params.customFilterInput.StartDateUtc = val[0];
      this.params.customFilterInput.EndDateUtc = val[1];

      this.$router.push({query: {
        date1: val[0],
        date2: val[1],
      }})
    },
  },
  computed: {
    getParams: function() {
      if (this.getRole === 'manager') {
        return `{
              Eq: {
                Name: "LocationID"
                Value: "${this.getUser.LocationID}"
              }
            }`;
      }
      return ``;
    }
  }
}
</script>