var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list',{attrs:{"title":"Departamente","search-by":['Name'],"show-filters":"","query-fields":[
                'ID',
                'Name',
                'ParentID',
                'Parent {Name}',
                'CreatedAt'
            ],"delete-mutation-type":"deleteDepartment","delete-mutation-field":"departmentIDs","export-query-type":"departmentsExport","delete-by":_vm.getRole !== 'operations' ? 'ID' : null,"query-type":"departments","add-link":"departments/add"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name"}},[_vm._v("Nume")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Data creării")])]},proxy:true},{key:"row",fn:function({item}){return [_c('ltd',{attrs:{"link":`departments/edit/${item.ID}`}},[_vm._v(_vm._s(item.Name))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }