var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list',{attrs:{"title":"Modele","search-by":['Name'],"show-filters":"","query-fields":[
              'ID',
              'VendorID',
              'Name',
              'NrOfStations',
              'Vendor {Name}',
              'CreatedAt'
          ],"delete-mutation-type":"deleteModel","delete-mutation-field":"modelIDs","export-query-type":"vendorModelsExport","delete-by":_vm.getRole !== 'operations' ? 'ID' : null,"query-type":"vendorModels","add-link":"models/add"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name"}},[_vm._v("Nume")]),_c('lth',[_vm._v("Producător")]),_c('lth',[_vm._v("NrOfStations")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Data creării")])]},proxy:true},{key:"row",fn:function({item}){return [_c('ltd',{attrs:{"link":`models/edit/${item.ID}`}},[_vm._v(_vm._s(item.Name))]),_c('ltd',{attrs:{"link":`vendors/edit/${item.VendorID}`}},[_vm._v(_vm._s(item.Vendor ? item.Vendor.Name : ''))]),_c('ltd',[_vm._v(_vm._s(item.NrOfStations))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }