import misc from "@/mixins/misc";
import router from "@/mixins/router";
import list from "@/mixins/list";
import restapi from "@/mixins/restapi";
import graphql from "@/mixins/graphql";

export default {

    methods: {
        ...misc.methods,
        ...router.methods,
        ...list.methods,
        ...restapi.methods,
        ...graphql.methods,

        goToPage: function (page, query = {}, params = {}) {
            let to = '';
            if (page !== '/') {
                to = `/${page}`
            }
            let rl = this.getRole;
            let path = `/sys/user/${rl}${to}`;
            if (path !== this.$route.path) {
                this.$router.push({path: path, query: query, params: params});
            }
        },

        toPage: function (page) {
            let to = '';
            if (page !== '/') {
                to = `/${page}`
            }
            let rl = this.getRole;
            return `/sys/user/${rl}${to}`;
        },

        goTo: function (page, query = {}, params = {}) {
            if (page !== this.$route.path) {
                this.$router.push({path: page, query: query, params: params});
            }
        },

        toGo: function (page) {
            return page;
        },

        togglePreloader: function (value = true) {
            this.$store.commit('togglePreloader', value);
        },

        goToLogin: function () {
            this.goTo('/login');
        },

        signOut: function () {
            this.$cookies.remove('ut');
            this.$cookies.remove('device_session');
            this.setUser({});
            localStorage.setItem('app.user', JSON.stringify({}));
            this.$nextTick(() => {
                this.goToLogin();
            });
        },

        setTheme: function () {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            if (this.$vuetify.theme.dark) {
                $('meta[name="theme-color"]').attr('content', '#272727')
                $('html').attr('dark-theme-mode', true);
            } else {
                $('html').removeAttr('dark-theme-mode');
                $('meta[name="theme-color"]').attr('content', '#f5f5f5');
            }
            this.$store.commit('setTheme', this.$vuetify.theme.dark);
        },

        setUser: function (user = {}) {
            this.$store.commit('setUser', user);
        },

        updateUser: function (user = {}) {
            this.$store.commit('updateUser', user);
            localStorage.setItem('app.user', JSON.stringify(this.getUser));
        },

        notify: function (text, status = 'success') {
            this.$toast[status](text, {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },

        handleThemeMode: function () {
            this.$vuetify.theme.dark = this.toBoolean(this.getTheme);
            if (this.$vuetify.theme.dark) {
                $('meta[name="theme-color"]').attr('content', '#272727')
                $('html').attr('dark-theme-mode', true);
            } else {
                $('meta[name="theme-color"]').attr('content', '#f5f5f5')
                $('html').removeAttr('dark-theme-mode');
            }

            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                if (!this.getTheme) {
                    if (!localStorage.getItem('app.theme')) {
                        this.setTheme();
                    }
                }
            }

            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
                if (this.getTheme && !e.matches || !this.getTheme && e.matches) {
                    this.setTheme(e.matches);
                }
            });
        }
    },

    computed: {
        ...misc.computed,
        ...router.computed,
        ...list.computed,
        ...restapi.computed,
        ...graphql.computed,

        getPreloader: function () {
            return this.$store.getters.getPreloader;
        },

        getMainRules: function () {
            return {
                positive: (value) => {
                    if (value) {
                        if (value <= !value) {
                            return 'The number must be positive';
                        }
                    }
                    return true;
                },
                required: value => !!value || 'Required',
                email: value => value && value.indexOf("@") >= 0 || 'Field must contain e-mail',
                counter: (value) => {
                    if (value) {
                        if (value.length >= 50) {
                            return 'Limit of characters has been reached';
                        }
                    }
                    return true;
                },
                boolean: value => !!value || 'Checkbox must be selected',
                password: value => value && value.length >= 6 || 'Password must have at least 6 characters',
            };
        },

        getTheme: function () {
            return this.toBoolean(this.$store.getters.getTheme);
        },

        getRole: function () {
            return this.getUser.Role ? this.getUser.Role.toLowerCase() : '';
        },

        isMobile: function() {
            return $(window).width() < 600;
        },

        isAuthorized: function () {
            console.log(process.env.NODE_ENV);
            // return process.env.NODE_ENV === 'development' ? !!(this.getUser.token) : this.$cookies.get('device_session');
            return !!(this.getUser.token);
        },

        getUser: function () {
            return this.$store.getters.getUser;
        },

        getBreadCrumbs: function () {
            if (!this.$route.meta && !this.route.meta.breadcrumbs) {
                return [];
            }
            let breadCrumbs = this.$route.meta.breadcrumbs || [];
            breadCrumbs = this.clone(breadCrumbs);

            breadCrumbs.forEach((it, i) => {
                if (i === breadCrumbs.length - 1) {
                    it.disabled = true;
                } else {
                    it.disabled = false;
                }
                if (it.href) {
                    it.href = this.replaceBreadcrumbsLink(it.href);
                    it.href = this.toPage(it.href);
                } else {
                    it.href = '';
                }

            });

            return breadCrumbs;
        }
    }

}