<template>
  <app-list title="Locații"
            show-filters
            :search-by="['Name', 'NewtonName', 'Address']"
            :query-fields="[
                'ID',
                'Name',
                'NewtonName',
                'Address',
                'CreatedAt'
            ]"
            delete-mutation-type="deleteLocation"
            delete-mutation-field="locationIDs"
            export-query-type="locationsExport"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="locations"
            add-link="locations/add">
    <template #head>
      <lth sort-by="Name">Nume</lth>
      <lth sort-by="NewtonName">Newton Nume</lth>
      <lth sort-by="Address">Adresă</lth>
      <lth sort-by="CreatedAt">Data creării</lth>
    </template>
    <template #row="{item}">
      <ltd :link="`locations/edit/${item.ID}`">{{ item.Name }}</ltd>
      <ltd>{{ item.NewtonName }}</ltd>
      <ltd>{{ item.Address }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  created() {
    this.$store.commit('setListSort', {
      direction: 'asc',
      field: 'Name'
    });
  }
}
</script>