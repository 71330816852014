<template>
  <div>
    <template v-if="text">
      <v-text-field :label="label" :clearable="clearable"
                    @click:clear="fieldValue = ''"
                    :disabled="disabled"
                    :dense="getListDense" flat filled outlined hide-details
                    v-model="fieldValue"/>
    </template>
    <template v-else-if="select || multiselect">
      <v-autocomplete :item-text="itemText"
                      :item-value="itemValue"
                      ref="autocomplete"
                      :label="label"
                      :disabled="disabled"
                      :search-input.sync="searchInput"
                      :clearable="clearable"
                      :dense="getListDense"
                      :multiple="multiselect"
                      flat filled outlined hide-details
                      v-model="fieldValue" :items="items"/>
    </template>
    <template v-else-if="date">
      <Date :single-date="singleDate" :clearable="clearable"
            :modal="modal" :dense="getListDense" outlined filled :label="`${label}`"
            v-model="fieldValue"/>
    </template>
  </div>
</template>
<script>
import Date from "@/components/page/list/components/Date";

export default {
  components: {Date},
  data() {
    return {
      timer: null,
      searchInput: null,

      fieldValue: this.value,
      range: false,
    }
  },

  created() {
    if (this.fieldValue) {
      let filters = [];
      if (this.$route.query.filters) {
        filters = JSON.parse(this.$route.query.filters);
      }
      let existentField = filters.find(f => f.field === this.field && f.value === this.fieldValue);
      if (!existentField) {
        if (!this.independent) {
          this.$store.commit('filterListBy', {
            field: this.field,
            value: this.fieldValue,
            type: this.getFilterType,
            filterInputName: this.filterInputName
          });
        }
      }
    }
  },

  mounted() {
  },

  watch: {
    getListFilters: function (filters) {
      filters.forEach(filter => {
        if (filter.field === this.field && filter.type === this.getFilterType) {
          this.fieldValue = filter.value;
        }
      });
    },

    fieldValue: function (value) {
      this.searchInput = '';


      window.clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('input', value);
        this.$emit('change', value);

        if (!this.field) {
          return false;
        }

        let existentFilter = this.$store.getters.getListFilters.find(f => f.field === this.field && f.value === value);
        if (!existentFilter) {
          if (!this.independent) {
            this.$store.commit('filterListBy', {
              field: this.field,
              value: this.fieldValue,
              type: this.getFilterType,
              filterInputName: this.filterInputName
            });
          }
        }
      }, 350);
    },

    value: function (value) {
      this.fieldValue = value;
    }
  },

  props: {
    itemText: {
      default() {
        return 'Name'
      }
    },
    itemValue: {
      default() {
        return 'ID'
      }
    },

    independent: Boolean,
    disabled: Boolean,
    text: Boolean,
    clearable: {
      default() {
        return true
      }
    },
    select: Boolean,
    multiselect: Boolean,
    modal: Boolean,
    date: Boolean,
    singleDate: Boolean,
    field: String,
    label: String,
    filterInputName: {
      default() {
        return 'default'
      }
    },
    items: Array,
    value: [String, Number, Array],
  },

  computed: {
    getListFilters: function () {
      return this.$store.getters.getListFilters;
    },

    getListDense: function () {
      return this.$parent.actions.dense;
    },

    getFilterType: function () {
      if (this.text) {
        return 'text';
      } else if (this.select) {
        return 'select';
      } else if (this.date) {
        return 'date';
      } else if (this.multiselect) {
        return 'multiselect';
      } else {
        return 'text';
      }
    }
  }
}
</script>