<template>
  <v-container fluid>

    <v-row>

      <v-col lg="4">
        <v-card
                class="mx-auto">
          <v-card-title>
            <v-icon class="mr-12" size="64">trending_up</v-icon>
            <v-row align="start">
              <div class="text-caption grey--text text-uppercase">
                Stații
              </div>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-sparkline
                :smooth="16"
                :gradient="['#1fa3ea']"
                :labels="heartbeats"
                :value="heartbeats"
                color=""
                :line-width="3"
                auto-draw
                width="300"
                stroke-linecap="round">
              <template v-slot:label="item">
                ${{ item.value }}
              </template>
            </v-sparkline>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="4">
        <v-card
                class="mx-auto">
          <v-card-title>
            <v-icon class="mr-12" size="64">trending_up</v-icon>
            <v-row align="start">
              <div class="text-caption grey--text text-uppercase">
                Stații
              </div>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-sparkline
                :smooth="16"
                :gradient="['#1fa3ea']"
                :labels="heartbeats"
                :value="heartbeats"
                color=""
                :line-width="3"
                auto-draw
                width="300"
                stroke-linecap="round">
              <template v-slot:label="item">
                ${{ item.value }}
              </template>
            </v-sparkline>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>
</template>
<script>
export default {
  data: () => ({
    items: [4, 4, 4, 8, 4, 12],
    heartbeats: [
      423,
      446,
      675,
      510,
      200,
      610,
      760,
      400,
      200,
      700,
    ],
  })
}
</script>