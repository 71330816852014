<template>
  <app-form :query-fields="[
              'VendorModelID',
              'GameID',
              'CabinetID',
              'LocationID',
              'StationID',
              'EventDateTime',
              'EventDate',
              'CreatedAt',
            ]"
            v-model="item"
            route-param="id"
            :query-object-params="query"
            query-type="simpleReport"
            title="Raport simplu">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID"
                            :items="games" v-model="item.GameID" outlined label="Denumire Joc"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID"
                            :items="vendors" v-model="item.VendorID" outlined label="Producător"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID"
                            :items="cabinets" v-model="item.CabinetID" outlined label="Cabinet"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID"
                            :items="models" v-model="item.VendorModelID" outlined label="Model"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
      query: {},

      games: [],
      vendors: [],
      cabinets: [],
      models: [],
    }
  },

  created() {
    this.query = JSON.parse(atob(this.$route.params.id));
  },

  mounted() {
    this.getGQLItems('games', [
      'Name',
      'ID',
    ]).then(res => {
      this.games = res.data.games.Items;
    });

   this.getGQLItems('vendorModels', [
      'Name',
      'ID',
    ]).then(res => {
      this.models = res.data.models.Items;
    });

    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ]).then(res => {
      this.vendors = res.data.vendors.Items;
    });

    this.getGQLItems('cabinets', [
      'Name',
      'ID',
    ]).then(res => {
      this.cabinets = res.data.cabinets.Items;
    });
  }
}
</script>