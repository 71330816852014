import ApolloClient from 'apollo-client'
import {createUploadLink} from "apollo-upload-client";
import {InMemoryCache} from "apollo-cache-inmemory";

const a = new ApolloClient({
    cache: new InMemoryCache(),
    // uri: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PROD_GRAPHQL_HOST : process.env.VUE_APP_GRAPHQL_HOST,
    link: createUploadLink({
        uri: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PROD_GRAPHQL_HOST : process.env.VUE_APP_GRAPHQL_HOST,
    }),
});
const b = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PROD_GRAPHQL_PRIVATE_HOST : process.env.VUE_APP_GRAPHQL_PRIVATE_HOST
    }),
});

export {a, b};