<template>
    <v-card flat rounded :class="{'app-form-section': true, 'no-margin': noMargin}">
        <slot></slot>
    </v-card>
</template>
<script>
export default {

    data() {
        return {}
    },

    props: {
      noMargin: Boolean,
    }

}
</script>
<style lang="scss" scoped>
.app-form-section {
  box-sizing: border-box;
  margin: 40px auto 0 auto;
  @media screen and (max-width: 600px) {
    margin: 10px auto 10px auto;
  }


  &.no-margin {
    margin: 0;
  }
}
</style>