<template>
  <app-form :query-fields="queryFields"
            :mutation-fields="mutationFields"
            v-model="item"
            @get="get"
            @save="save"
            ref="form"
            delete-mutation-type="deleteInventory"
            route-param="id"
            :attachments="attachments"
            delete-field="ids"
            mutation-type="saveInventory"
            query-type="inventory"
            title="Invetar">
    <template #actions="{mobile}">
      <v-tooltip :left="!mobile" :top="mobile">
        <template v-slot:activator="{on}">
          <v-btn @click="modal = !modal" v-on="on" :loading="getPreloader" icon>
            <v-icon>content_copy</v-icon>
          </v-btn>
        </template>
        <span>Dublează inregistrarea</span>
      </v-tooltip>

      <v-dialog max-width="350" v-model="modal">
        <v-card>
          <v-card-title>
            Dublează
          </v-card-title>
          <v-card-text>
            <v-text-field v-model.number="quantity" label="Număr de dublări"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="modal = false">
              Închide
            </v-btn>
            <v-btn :loading="getPreloader" color="primary" @click="duplicate">
              Salvează & Dublează
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template #default>

      <app-form-section>
        <app-form-section-block icon="info">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="item.InventoryNr" disabled
                            outlined label="Nr. Inventar"/>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="item.InventoryTypeID" :items="inventoryTypes"
                              @change="getTypeModels"
                              item-text="Name"
                              item-value="ID"
                              :rules="[getMainRules.required]"
                              outlined label="Tip"/>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="item.InventoryModelID" :items="inventoryModels"
                              item-text="Name"
                              item-value="ID"
                              outlined label="Model"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="item.SerialNr" :rules="[getMainRules.required]" outlined label="Serial Nr"/>
            </v-col>
            <v-col cols="6" v-if="getRole === 'admin' || getRole === 'operations'">
              <v-autocomplete v-model="item.LocationID" :items="locations"
                              item-text="Name"
                              item-value="ID"
                              :rules="[getMainRules.required]"
                              outlined label="Locație"/>
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="item.Color" outlined label="Culoare / Sigilii"/>
            </v-col>
            <v-col cols="6">
              <v-select :items="statuses" v-model="item.Status" :rules="[getMainRules.required]" outlined
                        label="Statut"/>
            </v-col>
            <v-col cols="6" v-if="item.Status === 'sold'">
              <app-datepicker v-model="item.SoldAt" outlined label="Vândut la"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="item.Details" outlined label="Detalii"/>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="item.InventoryVendorID" :items="inventoryVendors"
                              item-text="Name"
                              item-value="ID"
                              outlined label="Furnizor"/>
            </v-col>
            <v-col cols="12">
              <app-file multiple
                        v-model="attachments"
                        :items="item.Attachments"
                        accept=".pdf"
                        outlined label="Atașează fișiere"/>
            </v-col>
          </v-row>
        </app-form-section-block>
      </app-form-section>
    </template>
  </app-form>
</template>
<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      modal: false,
      quantity: 1,

      item: {
        LocationID: null,
        Attachments: []
      },

      attachments: [],

      inventoryVendors: [],
      inventoryTypes: [],
      inventoryModels: [],
      locations: [],
      queryFields: [
        'ID',
        'SerialNr',
        'Color',
        'InventoryNr',
        'Status',
        'Details',
        'Attachments {Name, OriginalName, ID, Size, Extension}',
        'InventoryVendorID',
        'InventoryTypeID',
        'InventoryModelID',
        'LocationID',
        'SoldAt',
      ],
      mutationFields: [
        'ID',
        'SerialNr',
        'Color',
        'Status',
        'Details',
        'InventoryVendorID',
        'InventoryTypeID',
        'InventoryModelID',
        'LocationID',
        'SoldAt',
      ],
      statuses: [
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Sold',
          value: 'sold',
        },
        {
          text: 'Depozit',
          value: 'stored',
        },
      ],
    }
  },

  mounted() {
    if (this.getRole === 'manager') {
      this.item.LocationID = this.getUser.LocationID;
    }
    this.getGQLItems('inventoryVendors', [
      'Name',
      'ID',
    ]).then(res => {
      this.inventoryVendors = res.data.inventoryVendors.Items;
    });

    this.getGQLItems('inventoryTypes', [
      'Name',
      'ID',
    ]).then(res => {
      this.inventoryTypes = res.data.inventoryTypes.Items;
    });

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ]).then(res => {
      this.locations = res.data.locations.Items;
    });
  },

  methods: {
    get: function() {
      if (this.getRole === 'manager') {
        this.item.LocationID = this.getUser.LocationID;
      }
      this.getTypeModels(this.item.InventoryTypeID);
    },

    save: function() {
      if (!this.$route.query.id) {
        this.attachments = [];
      }
    },

    getTypeModels: function (value) {
      let query = this.getSingleQuery('InventoryTypeID', value);

      this.getGQLItems('inventoryModels', [
        'Name',
        'ID',
      ], query).then(res => {
        this.inventoryModels = res.data.inventoryModels.Items;
      });
    },

    duplicate: function () {
      let mutationFields = this.getGQLMutationFromObject(this.mutationFields, this.item);
      mutationFields += `, Quantity: ${this.quantity}`;
      this.$apollo.mutate({
        mutation: gql`mutation  {
              saveInventoryMulti(input: {
                  ${mutationFields}
              })
             }
          `
      }).then(res => {
        this.modal = false;
        this.quantity = 0;
        this.$router.back();
        this.notify('Dublat cu succes');
      }).catch(res => {
        this.notify('Eroare tehnică la dublare', 'error');
      })
    }
  }
}
</script>