<template>
  <app-list title="Utilizatori"
            :search-by="['FirstName', 'LastName', 'PrimaryPhone', 'PrimaryEmail']"
            show-filters
            :query-fields="[
                'ID',
                'FirstName',
                'LastName',
                'PrimaryPhone',
                'PrimaryEmail',
                'Role',
                'CreatedAt'
            ]"
            delete-mutation-type="deleteUser"
            delete-mutation-field="userIDs"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="users"
            add-link="users/add"
            hide-filters>
    <template #head>
      <lth>Nume</lth>
      <lth>Telefon</lth>
      <lth>Email</lth>
      <lth>Rol</lth>
      <lth>Data creării</lth>
    </template>
    <template #row="{item}">
      <ltd :link="`users/edit/${item.ID}`">{{ item.FirstName }} {{ item.LastName }}</ltd>
      <ltd>{{ item.PrimaryPhone }}</ltd>
      <ltd>{{ item.PrimaryEmail }}</ltd>
      <ltd>{{ item.Role }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  data: () => ({

  })

}
</script>