<template>
  <app-list title="Registru de casă"
            v-if="getLocationID"
            :query-fields="[
                'ID',
                'OperationalDate',
                'Incoming',
                'Outgoing',
                'Profit',
                'NrOfIncomingTransactions',
                'NrOfOutgoingTransactions',
                'PreviousBalance',
                'LocationID',
                'Balance',
                'Location {Name}',
                'ClosedBy {FullName}',
            ]"
            single-select
            :query-params="params"
            delete-mutation-type="deleteClosedCashRegister"
            delete-mutation-field="id"
            :delete-by="getRole === 'admin' || getRole === 'operations' ? 'ID' : null"
            :height="210"
            query-type="closedCashRegisters">
    <template #filters>
      <v-col v-if="getRole !== 'manager' && getRole !== 'marketing'" cols="12" md="4" lg="3" xl="2">
        <lf v-model="selectedLocationID" :clearable="false" field="LocationID" select :items="locations" label="Locație"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf modal field="OperationalDate" date label="Data operațională"/>
      </v-col>
    </template>
    <template #head>
      <lth sort-by="Date">Data</lth>
      <lth>Sold start</lth>
      <lth>Sold end</lth>
      <lth>Manager</lth>
      <lth>Registru de casa</lth>
      <lth>Decont</lth>
    </template>
    <template #row="{item}">
      <ltd date>{{ item.OperationalDate }}</ltd>
      <ltd amount>{{ item.PreviousBalance }}</ltd>
      <ltd amount>{{ item.Balance }}</ltd>
      <ltd>
        {{ item.ClosedBy ? item.ClosedBy.FullName : '' }}
      </ltd>
      <ltd>
        <v-btn icon @click="closedCashRegisterExport(item.OperationalDate)">
          <v-icon>download</v-icon>
        </v-btn>
      </ltd>
      <ltd>
        <v-btn icon @click="closedCashRegisterDecontExport(item.OperationalDate)">
          <v-icon>download</v-icon>
        </v-btn>
      </ltd>
    </template>
  </app-list>
</template>
<script>
import gql from "graphql-tag";

export default {

  data() {
    return {
      operationalDate: null,
      dayClosed: false,
      selectedLocationID: null,
      locations: [],
    }
  },

  created() {
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.locations = res.data.locations.Items;
      if (this.locations.length > 0) {
        this.selectedLocationID = this.locations[0].ID;
      }
    });
  },

  mounted() {
    this.$store.commit('setListSort', {
      direction: 'desc',
      field: 'OperationalDate'
    });
  },

  methods: {
    closedCashRegisterExport: function (date = null) {
      let locationId = this.getLocationID;
      date = this.$moment(date).format('YYYY-MM-DD');
      this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query {
            closedCashRegisterExport(operationalDate: "${date}", locationID: "${locationId}") {
                Export {
                    Excel {
                        ID
                    }
                }
            }
        }`
      }).then(res => {
        let fileID = res.data.closedCashRegisterExport.Export.Excel.ID;
        this.notify('Descărcare fișier..');
        setTimeout(() => {
          window.open(`/api/downloadFileByUUID/${fileID}`);
        }, 500);
      });
    },

    closedCashRegisterDecontExport: function (date) {
      let locationId = this.getLocationID;
      date = this.$moment(date).format('YYYY-MM-DD');
      this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query {
            closedCashRegisterDecontExport(operationalDate: "${date}", locationID: "${locationId}") {
                Export {
                    Excel {
                        ID
                    }
                }
            }
        }`
      }).then(res => {
        let fileID = res.data.closedCashRegisterDecontExport.Export.Excel.ID;
        this.notify('Descărcare fișier..');
        setTimeout(() => {
          window.open(`/api/downloadFileByUUID/${fileID}`);
        }, 500);
      });
    },
  },

  computed: {
    getLocationID: function() {
      return this.getRole !== 'manager' && this.getRole !== 'marketing' ? this.selectedLocationID : this.getUser.LocationID;
    },

    params: function () {
      if (this.getRole !== 'manager' && this.getRole !== 'marketing') {
        return ``;
      }
      return `{
            Eq: {
              Name: "LocationID"
              Value: "${this.getUser.LocationID}"
            }
          }`;
    }
  }
}
</script>