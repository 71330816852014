<template>
  <v-dialog
      ref="dialog"
      v-model="modal"
      width="290px">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <slot></slot>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      modal: this.value,
    }
  },

  props: {
    value: Boolean,
  },

  watch: {
    value: function(value) {
      this.modal = value;
    },
    modal: function(value) {
      this.$emit('input', value);
    },
  }
}
</script>