export default {

    methods: {
        capitalizeFirst: function (s) {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        },

        isNumber: function (str) {
            return /^[0-9]+(\.)?[0-9]*$/.test(str)
        },

        formatDate: function(date) {
            if (this.$moment(date).format('YYYY-MM-DD') !== 'Invalid date') {
                return this.$moment(date).format('YYYY-MM-DD');
            }
            return '';
        },

        formatAmount: function (num) {
            let number = parseFloat(num);
            let nf = new Intl.NumberFormat('ro-RO');
            return nf.format(number);
        },

        getFirstLetter: function (string) {
            if (!string) {
                return '';
            }
            return string.charAt(0);
        },

        /**
         *
         * @param value
         * @returns {boolean}
         */
        toBoolean: function (value) {
            if (value == 'true' || value == '1' || value == 'active' || value == true) {
                value = true;
            } else {
                value = false;
            }
            return value;
        },

        getBooleanText: function (value) {
            if (value == 'true' || value == '1' || value == 'active' || value == true) {
                return this.__('Yes');
            } else {
                return this.__('No');
            }
        },

        /**
         * Clones the object to get unlinked variable
         * @param object
         * @returns {any}
         */
        clone: function (object) {
            return JSON.parse(JSON.stringify(object));
        },

        round: function (num) {
            return Math.round(num * 100) / 100;
        },

        floor: function (num) {
            return Math.floor(num * 100) / 100;
        },
    },

    computed: {}

}