<template>
  <app-form :query-fields="queryFields"
            :mutation-fields="[
                'ID',
                'GameID',
                'VendorID',
                'CabinetID',
                'VendorModelID',
                'ProfitMultiplier',
                'LicenseDate',
                'NrOfPosts',
                'AcceptorModel',
                'CardReaderType',
                'FabricationYear',
                'CvtDate',
                'ConversionAvailable',
                'NewtonInterfaceType',
                'Invoice',
                'Appendix',
                'LeaseAgreement',
                'ResetDate',
                'PurchaseContract',
                'Info',
            ]"
            v-model="item"
            delete-mutation-type="deleteStation"
            @get="get"
            route-param="id"
            delete-field="stationIDs"
            mutation-type="saveStation"
            query-type="station"
            :title="`Stație ${item.NewtonID} / ${item.SerialNr}`">
    <v-dialog max-width="500" v-model="modal">
      <v-card flat tile>
        <v-card-title class="pl-3 pr-3">
          Selectează o stație
        </v-card-title>
        <v-divider/>
        <v-text-field clearable
                      @change="searchStations"
                      class="pl-3 pr-3"
                      @click:clear="searchStations"
                      solo
                      flat
                      label="Caută"
                      hide-details
                      prepend-icon="search"/>
        <v-divider/>
      </v-card>

      <template v-if="loadingStations">
        <v-card flat tile>
          <v-card-text class="pt-5">
            <v-layout justify-center class="p-10">
              <v-progress-circular indeterminate size="85" color="primary"/>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>

      <v-card v-if="!loadingStations"
              class="overflow-y-auto"
              flat
              tile
              max-height="600">
        <v-list nav>
          <v-list-item-group v-model="selectedStation">
            <v-list-item selectable
                         three-line
                         v-show="station.isSearched"
                         color="primary"
                         :value="station"
                         :key="station.ID"
                         v-for="station in stations">
              <v-lazy width="100%">
                <v-layout align-center>
                  <v-list-item-avatar>
                    <v-avatar color="blue-grey lighten-4" size="56">
                      ST
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ station.SerialNr }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Locație: <b>{{ station.Location.Name }}</b>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      NewtonID: <b>{{ station.NewtonID }}</b>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip top>
                      <template #activator="{on}">
                        <v-btn @click="openStation(station)" icon v-on="on">
                          <v-icon>subdirectory_arrow_right</v-icon>
                        </v-btn>
                      </template>
                      <span>Deschide stația in tab nou</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-layout>
              </v-lazy>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card flat tile>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed
                 @click="modal = false">
            Anulează
          </v-btn>
          <v-btn :disabled="!selectedStation.ID"
                 color="primary"
                 depressed
                 @click="replaceStation">
            Înlocuiește stația curentă
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <template #actions="{mobile}">
      <v-tooltip :left="!mobile" :top="mobile">
        <template v-slot:activator="{on}">
          <v-btn @click="modal = !modal" v-on="on" :loading="getPreloader" icon>
            <v-icon>copy_all</v-icon>
          </v-btn>
        </template>
        <span>Înlocuiește stația curentă cu alta</span>
      </v-tooltip>
    </template>
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID" @change="getVendorItems" hide-details
                            :items="vendors" v-model="item.VendorID" outlined label="Producător"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID" @change="getCabinets" hide-details
                            :items="vendorModels" v-model="item.VendorModelID" outlined label="Model"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID" hide-details
                            :items="cabinets" v-model="item.CabinetID" outlined label="Cabinet"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete item-text="Name" item-value="ID" prepend-inner-icon="casino" hide-details
                            :items="games" v-model="item.GameID" outlined label="Denumire Joc"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model.number="item.ProfitMultiplier" type="number" hide-details
                          outlined label="ProfitMultiplier"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field disabled v-model="item.SerialNr" type="text" hide-details
                          outlined label="SerialNr"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model.number="item.NrOfPosts" hide-details
                          type="number" outlined label="NrOfPosts"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.CardReaderType" hide-details
                          prepend-inner-icon="payments" outlined label="CardReaderType"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field hide-details v-model="item.AcceptorModel" outlined label="AcceptorModel"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field hide-details v-model="item.NewtonInterfaceType" outlined label="NewtonInterfaceType"/>
          </v-col>
        </v-row>
      </app-form-section-block>
      <app-form-section-block icon="calendar_today">
        <v-row>
          <v-col cols="12" lg="6">
            <app-datepicker hide-details v-model="item.ResetDate" outlined label="ResetDate"/>
          </v-col>
          <v-col cols="12" lg="6">
            <app-year-picker v-model.number="item.FabricationYear" outlined label="FabricationYear"/>
          </v-col>
          <v-col cols="12" lg="6">
            <app-datepicker hide-details v-model="item.LicenseDate" outlined label="LicenseDate"/>
          </v-col>
          <v-col cols="12" lg="6">
            <app-datepicker hide-details v-model="item.CvtDate" outlined label="CvtDate"/>
          </v-col>
        </v-row>
      </app-form-section-block>
      <app-form-section-block icon="description">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.LeaseAgreement" hide-details outlined label="Contract chirie"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.Appendix" hide-details outlined label="Anexa"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.PurchaseContract" hide-details outlined label="Contract cumparare"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field hide-details v-model="item.Invoice" outlined label="Factura"/>
          </v-col>
          <v-col cols="12" lg="12">
            <v-textarea hide-details v-model="item.Info" outlined label="Comentariu"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      modal: false,
      loadingStations: false,
      selectedStation: {
        ID: null,
      },

      item: {
        SerialNr: null,
        ProfitMultiplier: 0.01,
        NrOfPosts: 0,
        FabricationYear: 0,

        LicenseDate: null,
        ResetDate: null,
        CvtDate: null,

        VendorID: '',
        VendorModelID: '',
        Info: '',
      },

      queryFields: [
        'ID',
        'GameID',
        'VendorID',
        'VendorModelID',
        'Location {Name}',
        'CabinetID',
        'ProfitMultiplier',
        'NewtonID',
        'LicenseDate',
        'SerialNr',
        'NrOfPosts',
        'AcceptorModel',
        'CardReaderType',
        'FabricationYear',
        'CvtDate',
        'ConversionAvailable',
        'NewtonInterfaceType',
        'Invoice',
        'Appendix',
        'LeaseAgreement',
        'ResetDate',
        'PurchaseContract',
        'Info',
      ],

      games: [],
      vendors: [],
      cabinets: [],
      vendorModels: [],
      stations: [],
    }
  },

  mounted() {
    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ]).then(res => {
      this.vendors = res.data.vendors.Items;
    });
  },

  watch: {
    modal: function (val) {
      if (val && this.stations.length === 0) {
        this.getStations();
      }
    }
  },

  methods: {
    openStation: function (station) {
      let routeData = this.$router.resolve({path: `${station.ID}`});
      window.open(routeData.href, '_blank');
    },

    searchStations: function (searchValue) {
      this.stations.forEach(st => {
        if (searchValue) {
          if (
              String(st.NewtonID).indexOf(searchValue) >= 0
              ||
              st.Location && st.Location.Name.indexOf(searchValue) >= 0
              ||
              st.SerialNr.indexOf(searchValue) >= 0
          ) {
            st.isSearched = true;
          } else {
            st.isSearched = false;
          }
        } else {
          st.isSearched = true;
        }

      });
    },

    replaceStation: function () {
      Object.keys(this.selectedStation).forEach(key => {
        let value = this.selectedStation[key];

        if (key !== 'ID'
            && key !== 'NewtonID'
            && typeof value !== 'object') {
          this.item[key] = value;
        }
      });
      this.modal = false;
    },

    getStations: function () {
      this.loadingStations = true;
      this.getGQLItems('stations', this.queryFields).then(res => {
        res.data.stations.Items.forEach(i => {
          i.isSearched = true;
        });

        this.stations = res.data.stations.Items;
        this.loadingStations = false;
      });
    },

    getVendorItems: function () {
      this.getVendorModels(this.item.VendorID);
      this.getGames(this.item.VendorID);
      this.item.GameID = '';
      this.item.VendorModelID = '';
      this.item.CabinetID = '';
    },

    get: function () {
      this.getVendorModels(this.item.VendorID);
      this.getCabinets(this.item.VendorModelID);
      this.getGames(this.item.VendorID);
    },

    getGames: function (value) {
      let query = this.getSingleQuery('VendorID', value);

      this.getGQLItems('games', [
        'Name',
        'ID',
      ], query).then(res => {
        this.games = res.data.games.Items;
      });
    },

    getVendorModels: function (value) {
      let query = this.getSingleQuery('VendorID', value);

      this.getGQLItems('vendorModels', [
        'Name',
        'ID',
      ], query).then(res => {
        this.vendorModels = res.data.vendorModels.Items;
      });
    },

    getCabinets: function (value) {
      let query = this.getSingleQuery('VendorModelID', value);
      this.getGQLItems('cabinets', [
        'Name',
        'ID',
      ], query).then(res => {
        this.cabinets = res.data.cabinets.Items;
      });
    },

  }
}
</script>