<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <slot></slot>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      menu: this.value,
    }
  },

  props: {
    value: Boolean,
  },

  watch: {
    value: function(value) {
      this.menu = value;
    },
    menu: function(value) {
      this.$emit('input', value);
    },
  }
}
</script>