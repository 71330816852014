var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list',{attrs:{"title":"Modele","search-by":['Name'],"show-filters":"","large-search":"","query-fields":[
              'ID',
              'Name',
              'InventoryTypeID',
              'InventoryType {Name}',
              'CreatedAt'
          ],"delete-mutation-type":"deleteInventoryModel","delete-mutation-field":"ids","export-query-type":"inventoryModelsExport","delete-by":_vm.getRole !== 'operations' ? 'ID' : null,"query-type":"inventoryModels","add-link":"models/add"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name"}},[_vm._v("Denumire")]),_c('lth',[_vm._v("Tip")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Data creării")])]},proxy:true},{key:"row",fn:function({item}){return [_c('ltd',{attrs:{"link":`inventory/models/edit/${item.ID}`}},[_vm._v(_vm._s(item.Name))]),_c('ltd',{attrs:{"link":`inventory/types/edit/${item.InventoryTypeID}`}},[_vm._v(_vm._s(item.InventoryType ? item.InventoryType.Name : ''))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }