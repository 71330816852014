<template>
  <div id="app">
    <v-overlay v-model="loading">
      <v-progress-circular
          indeterminate
          size="128">
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Vue from "vue";
import View from './views/View';
import vuetify from "./plugins/vuetify/vuetify";
import store from './plugins/store/store'
import './plugins/toastify';
import router from "@/plugins/router";
import VueApollo from "vue-apollo";
import {a, b} from '@/plugins/apollo';

const apolloProvider = new VueApollo({
  defaultClient: b,
  clients: {
    a,
    b
  }
});

export default {
  name: 'App',

  data: () => ({
    loading: true,
  }),

  created() {
    this.handleThemeMode();
    this.bootstrap().then(() => {
      this.loading = false;
      this.$nextTick(() => {
        this.render();
      });
    }).catch(() => {
      this.loading = false;
      this.render();
      this.$nextTick(() => {
        this.signOut();
      });
    });
  },

  methods: {
    render: () => {
      new Vue({
        vuetify,
        router,
        store,
        apolloProvider,

        created() {
          this.routerLoad();
          this.routerBeforeEach(router);
        },

        render: h => h(View)
      }).$mount('#app')
    },
  },

  computed: {}
}
</script>
