<template>
  <v-layout align-center justify-end>
    <v-tooltip v-if="searchBy" bottom>
      <template v-slot:activator="{on}">
        <v-btn icon
               :small="isMobile"
               value="search"
               :class="{'v-btn--active': buttons.filters, 'mr-1': true}"
               v-on="on"
               @click="handleQuickSearch(!buttons.filters)">
          <v-icon>search</v-icon>
        </v-btn>
      </template>
      <span>Caută</span>
    </v-tooltip>

    <v-tooltip v-if="exportQueryType" bottom>
      <template v-slot:activator="{on}">
        <v-btn icon
               :loading="loading.export"
               :small="isMobile"
               value="search"
               class="mr-1"
               v-on="on"
               @click="handleExport">
          <v-icon>get_app</v-icon>
        </v-btn>
      </template>
      <span>Exportă</span>
    </v-tooltip>

    <v-tooltip v-if="buttons.selectMode && deleteBy" bottom>
      <template v-slot:activator="{on}">
        <v-btn icon value="deleteSelected"
               :small="isMobile"
               class="mr-1"
               v-on="on"
               @click="handleDeleteSelected">
          <v-icon color="error">delete</v-icon>
        </v-btn>
      </template>
      <span>Șterge selectate</span>
    </v-tooltip>

    <v-tooltip v-if="deleteBy || selectable" bottom>
      <template v-slot:activator="{on}">
        <v-btn icon
               :small="isMobile"
               :class="{'v-btn--active': buttons.selectMode, 'mr-1': true}"
               value="select"
               v-on="on" @click="handleSelect(!buttons.selectMode)">
          <v-icon>checklist</v-icon>
        </v-btn>
      </template>
      <span>Selectează datele</span>
    </v-tooltip>

    <v-menu v-if="options && options.length > 0" offset-y>
      <template #activator="{on: onMenu}">
        <v-tooltip bottom>
          <template v-slot:activator="{on: onTooltip}">
            <v-btn
                icon
                :small="isMobile"
                value="options"
                v-on="{...onTooltip, ...onMenu}">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <span>Opțiuni</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
            @click="item.action"
            link
            v-for="(item, index) in options"
            :key="index">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn icon
               :small="isMobile"
               value="dense"
               :class="{'v-btn--active': buttons.dense, 'mr-1': true}"
               v-on="on"
               @click="handleDense(!buttons.dense)">
          <v-icon>reorder</v-icon>
        </v-btn>
      </template>
      <span>Listă îngustă</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn icon
               :small="isMobile"
               value="filters"
               :class="{'v-btn--active': buttons.filters, 'mr-1': true}"
               v-if="!hideFilters"
               @click="handleFilters(!buttons.filters)" v-on="on">
          <v-icon>filter_list</v-icon>
        </v-btn>
      </template>
      <span>Filtre</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn icon
               color="primary"
               class="v-btn--active"
               :small="isMobile"
               value="add"
               v-if="addLink" exact :to="addLink" v-on="on">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Adaugă</span>
    </v-tooltip>

    <slot></slot>
  </v-layout>
</template>
<script>
export default {

  data() {
    return {
      buttons: {
        filters: this.toBoolean(localStorage.getItem('app.list.actions.filters')),
        dense: this.toBoolean(localStorage.getItem('app.list.actions.dense')),
        selectMode: false,
      },
      loading: {
        export: false,
      }
    }
  },

  props: {
    addLink: String,
    hideFilters: Boolean,
    selectable: Boolean,
    searchBy: Array,
    options: Array,
    exportQueryType: String,
    deleteBy: String,
  },

  mounted() {
    if (this.buttons.dense) {
      this.handleDense(true);
    }
    if (this.buttons.filters) {
      this.handleFilters(true);
    }
  },

  methods: {
    handleSelect: function (value) {
      this.buttons.selectMode = value;
      this.$emit('action', {
        selectMode: this.buttons.selectMode
      });
    },

    handleExport: function () {
      this.loading.export = true;
      this.getParent.exportFile().then(() => {
        this.loading.export = false;
      });
    },

    handleDense: function (value) {
      this.buttons.dense = value;
      localStorage.setItem('app.list.actions.dense', this.buttons.dense);
      this.$emit('action', {
        dense: this.buttons.dense
      });
    },

    handleDeleteSelected: function () {
      let confirmation = confirm('Ești sigur?');
      if (confirmation) {
        this.$emit('delete');
        this.buttons.selectMode = false;
      }
    },

    handleFilters: function (value) {
      this.buttons.filters = value;
      localStorage.setItem('app.list.actions.filters', this.buttons.filters);
      this.$emit('action', {
        filters: this.buttons.filters
      });
    },

    handleQuickSearch: function (value) {
      this.handleFilters(value);
      this.$nextTick(() => {
        if (this.buttons.filters) {
          this.$emit('focusSearch');
        }
      });
    }
  },
  computed: {
    getParent: function() {
      return this.$parent.$parent.$parent;
    }
  }
}
</script>