<template>
  <app-form :query-fields="[
              'Name',
              'DepartmentID',
              'ID',
            ]"
            v-model="item"
            route-param="id"
            query-id-field="id"
            delete-mutation-type="deleteExpenditureType"
            delete-field="expenditureTypeIDs"
            mutation-type="saveExpenditureType"
            query-type="expenditureType"
            title="Tip cheltuiala">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12">
            <v-autocomplete :items="departments" v-model="item.DepartmentID" item-text="Name" item-value="ID" outlined label="Departament"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Nume"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
      departments: []
    }
  },

  mounted() {
    this.getGQLItems('departments', [
      'Name',
      'ID',
    ]).then(res => {
      this.departments = res.data.departments.Items;
    });
  }
}
</script>