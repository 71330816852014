<template>
  <app-form :query-fields="[
              'Name',
              'NewtonName',
              'Address',
              'HallNr',
              'SimpleReportSync',
              'ID',
            ]"
            v-model="item"
            delete-mutation-type="deleteLocation"
            route-param="id"
            delete-field="locationIDs"
            mutation-type="saveLocation"
            query-type="location"
            title="Locație">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Nume"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.Address" :rules="[getMainRules.required]" outlined label="Adresă"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="item.NewtonName" :rules="[getMainRules.required]" outlined label="Newton Nume"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model.number="item.HallNr" :rules="[getMainRules.required]" outlined label="HallNr"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-checkbox v-model="item.SimpleReportSync" label="Report sync"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {
        SimpleReportSync: false,
        HallNr: 0,
      },
    }
  },
}
</script>