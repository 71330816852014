var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-form',{attrs:{"query-fields":[
            'Explanation',
            'DepartmentID',
            'LocationID',
            'ExpenditureTypeID',
            'Amount',
            'Date',
            'ID',
          ],"delete-mutation-type":"deleteExpenditure","route-param":"id","query-id-field":"id","delete-field":"expenditureIDs","mutation-type":"savePayment","query-type":"payment","title":"Cheltuiala"},on:{"get":_vm.get},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('app-form-section',[_c('app-form-section-block',{attrs:{"icon":"info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.departments,"item-text":"Name","item-value":"ID","outlined":"","label":"Departament"},on:{"change":_vm.getExpenditures},model:{value:(_vm.item.DepartmentID),callback:function ($$v) {_vm.$set(_vm.item, "DepartmentID", $$v)},expression:"item.DepartmentID"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.expenditures,"item-text":"Name","item-value":"ID","outlined":"","label":"Tip cheltuiala"},model:{value:(_vm.item.ExpenditureTypeID),callback:function ($$v) {_vm.$set(_vm.item, "ExpenditureTypeID", $$v)},expression:"item.ExpenditureTypeID"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('app-datepicker',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"Data"},model:{value:(_vm.item.Date),callback:function ($$v) {_vm.$set(_vm.item, "Date", $$v)},expression:"item.Date"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.getMainRules.required],"outlined":"","label":"Suma"},model:{value:(_vm.item.Amount),callback:function ($$v) {_vm.$set(_vm.item, "Amount", _vm._n($$v))},expression:"item.Amount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Furnizor/Detalii"},model:{value:(_vm.item.Explanation),callback:function ($$v) {_vm.$set(_vm.item, "Explanation", $$v)},expression:"item.Explanation"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }