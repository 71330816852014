<template>
  <app-form :query-fields="[
              'Name',
              'VendorID',
              'ID',
            ]"
            v-model="item"
            delete-mutation-type="deleteGame"
            route-param="id"
            delete-field="gameIDs"
            mutation-type="saveGame"
            query-type="game"
            title="Joc">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="item.VendorID" :items="vendors"
                            item-text="Name"
                            item-value="ID"
                            :rules="[getMainRules.required]"
                            outlined label="Producător"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="item.Name" :rules="[getMainRules.required]" outlined label="Denumire joc"/>
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {},
      vendors: []
    }
  },

  mounted() {
    this.getGQLItems('vendors', [
      'Name',
      'ID',
    ]).then(res => {
      this.vendors = res.data.vendors.Items;
    });
  }
}
</script>