<template>
  <app-list title="Tranzacții - Registru de Casă"
            v-if="getRole === 'manager' && operationalDate || operationalDate && selectedLocationID"
            ref="list"
            :additional-query-fields="['Optional']"
            :query-fields="[
                'ID',
                'Date',
                'OperationalDate',
                'DocumentNrTranslated',
                'ExplanationTranslated',
                'Amount',
                'Direction',
                'TypeID',
                'Type {Name}',
                'CreatedAt',
            ]"
            :additional-query-params="params"
            @actions="actions = $event"
            @response="response = $event"
            :height="210"
            query-type="cashRegisterPayments">
    <template #actions>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn color="primary" @click="setToday" v-on="on" class="mr-1 v-btn--active" icon>
            <v-icon>today</v-icon>
          </v-btn>
        </template>
        <span>Astăzi {{ currentOperationalDate }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn color="success" v-on="on" icon class="mr-1" @click="$refs.list.get()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>
        Reîncarcă datele
      </v-tooltip>
      <v-btn class="mr-1" text outlined :disabled="!cashRegisterCloseDays" @click="closeCashRegister">
        Închide ziua
        <template v-if="cashRegisterCloseDays">
          {{ $moment(cashRegisterCloseDays).format('DD/MM/YYYY') }}
        </template>
      </v-btn>
      <v-btn :disabled="!response.Optional.IsClosed" @click="closedCashRegisterExport" class="mr-1" text outlined>
        Generează registru
      </v-btn>
      <v-btn :disabled="!response.Optional.IsClosed" @click="closedCashRegisterDecontExport" text outlined>
        Generează decont
      </v-btn>

    </template>
    <template #filters>
      <v-col v-if="getRole !== 'manager'" cols="12" md="4" lg="3" xl="2">
        <lf :clearable="false" independent field="LocationID" select :items="locations" label="Locație" @change="hasUnClosedDay" v-model="selectedLocationID"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
          <lf independent :value="[operationalDate, operationalDate]" :clearable="false" single-date date
              field="OperationalDate" label="Data operațională" @input="handleDateChange"/>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <lf field="TypeID" multiselect :items="paymentTypes" label="Tip"/>
      </v-col>
    </template>
    <template #head>
      <lth width="90" sort-by="Date">Nr Crt.</lth>
      <lth sort-by="Date">Data Înreg.</lth>
      <lth>Document Nr</lth>
      <lth sort-by="TypeID">Explicația</lth>
      <lth sort-by="Amount">Incasat</lth>
      <lth sort-by="Amount">Plați</lth>
    </template>
    <template #bottom-head>
      <tr class="head-row">
        <th></th>
        <th></th>
        <th></th>
        <th class="text-right">Sold ziua precedenta</th>
        <th>{{ response.Optional.PrevBalance || 0 }}</th>
        <th></th>
      </tr>
    </template>
    <template #row="{item, index}">
      <ltd>{{ index + 1 }}</ltd>
      <ltd date>{{ item.Date }}</ltd>
      <ltd>{{ item.DocumentNrTranslated }}</ltd>
      <ltd>{{ item.ExplanationTranslated }}</ltd>
      <ltd amount>{{ !toBoolean(item.Direction) ? item.Amount : 0 }}</ltd>
      <ltd amount>{{ toBoolean(item.Direction) ? item.Amount : 0 }}</ltd>
    </template>
    <template #footer="{items}">
      <tr>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth class="text-right">Rulaj zi</lth>
        <lth>{{ getTotal('Amount', getItems(items, false)) }}</lth>
        <lth>{{ getTotal('Amount', getItems(items, true)) }}</lth>
      </tr>
      <tr>
        <lth></lth>
        <lth></lth>
        <lth></lth>
        <lth class="text-right">Sold final</lth>
        <lth>{{ response.Optional.CurrentBalance || 0 }}</lth>
        <lth></lth>
      </tr>
    </template>
  </app-list>
</template>
<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      locations: [],
      paymentTypes: [],
      dayClosed: false,
      selectedLocationID: null,
      actions: {},
      operationalDate: null,
      currentOperationalDate: null,
      cashRegisterCloseDays: null,
      queryFilters: {
        locationID: null
      },
      response: {
        Optional: {
          Incoming: 0,
          Outgoing: 0,
          Balance: 0,
          IsClosed: false,
        },
      },
    }
  },

  created() {
    this.queryFilters.locationID = this.getUser.LocationID;
    this.$store.commit('setListSort', {
      direction: 'desc',
      field: 'CreatedAt'
    });

    this.$apollo.query({
      fetchPolicy: 'no-cache',
      query: gql`query {
          currentOperationalDate
      }`
    }).then(res => {
      this.operationalDate = res.data.currentOperationalDate;
      this.currentOperationalDate = res.data.currentOperationalDate;
    });
  },

  mounted() {
    if (this.getRole !== 'admin' && this.getRole !== 'operations') {
      this.hasUnClosedDay();
    }
    let sortQuery = this.getSortQuery('Name', 'ASC');

    this.getGQLItems('locations', [
      'Name',
      'ID',
    ]).then(res => {
      this.locations = res.data.locations.Items;
      if (this.locations.length > 0) {
        this.selectedLocationID = this.locations[0].ID;
      }
      if (this.getRole === 'admin' || this.getRole === 'operations') {
        this.hasUnClosedDay();
      }
    });

    this.getGQLItems('paymentTypes', [
      'Name',
      'ID',
    ], sortQuery).then(res => {
      this.paymentTypes = res.data.paymentTypes.Items;
    });
  },

  methods: {
    setToday: function () {
      this.operationalDate = this.currentOperationalDate;
    },

    hasUnClosedDay: function () {
      let locationId = this.getLocationID;

      return this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query {
            cashRegisterCloseDays(locationID: "${locationId}") {
                OperationalDate
            }
        }`
      }).then(res => {
        if (res.data.cashRegisterCloseDays.length > 0) {
          this.cashRegisterCloseDays = res.data.cashRegisterCloseDays[0].OperationalDate;
        } else {
          this.cashRegisterCloseDays = null;
        }
        return res;
      });

    },

    closeCashRegister: function () {
      let confirmation = confirm('Ești sigur?');
      if (!confirmation) {
        return false;
      }
      let locationId = this.getLocationID;

      this.$apollo.mutate({
        mutation: gql`mutation {
            closeCashRegister(input: {
                OperationalDate: "${this.cashRegisterCloseDays}",
                LocationID: "${locationId}"
            })
        }`
      }).then(res => {
        if (res.data) {
          this.dayClosed = true;
          this.operationalDate = this.cashRegisterCloseDays;
          this.notify('Ziua închisă cu succes');
          this.hasUnClosedDay().then(res => {
            this.$refs.list.get();
          });
        } else {
          this.notify('Ziua nu a fost închisă cu succes', 'error');
        }
      }).catch(res => {
        this.notify('Ziua nu a fost închisă cu succes', 'error');
      });

    },

    closedCashRegisterExport: function () {
      let locationId = this.getLocationID;

      this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query {
            closedCashRegisterExport(operationalDate: "${this.operationalDate}", locationID: "${locationId}") {
                Export {
                    Excel {
                        ID
                    }
                }
            }
        }`
      }).then(res => {
        let fileID = res.data.closedCashRegisterExport.Export.Excel.ID;
        this.notify('Descărcare fișier..');
        setTimeout(() => {
          window.open(`/api/downloadFileByUUID/${fileID}`);
        }, 500);
      });
    },

    closedCashRegisterDecontExport: function () {
      let locationId = this.getLocationID;
      this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query {
            closedCashRegisterDecontExport(operationalDate: "${this.operationalDate}", locationID: "${locationId}") {
                Export {
                    Excel {
                        ID
                    }
                }
            }
        }`
      }).then(res => {
        let fileID = res.data.closedCashRegisterDecontExport.Export.Excel.ID;
        this.notify('Descărcare fișier..');
        setTimeout(() => {
          window.open(`/api/downloadFileByUUID/${fileID}`);
        }, 500);
      });
    },
    handleDateChange: function (val) {
      this.operationalDate = val[0];
    },

    getItems: function (items, direction = false) {
      let filtered = [];
      items.forEach(i => {
        if (i.Direction == direction) {
          filtered.push(i);
        }
      });

      return filtered;
    }
  },

  computed: {
    params: function () {
      if (this.getRole !== 'manager' && this.selectedLocationID) {
        return `
                operationalDate: "${this.operationalDate}",
                locationID: "${this.selectedLocationID}"
        `;
      } else if (this.getRole === 'manager') {
        return `
          locationID: "${this.queryFilters.locationID}",
          operationalDate: "${this.operationalDate}"
      `;
      }
      return `operationalDate: "${this.operationalDate}"`;
    },

    getLocationID: function() {
      return this.getRole !== 'manager' && this.getRole !== 'marketing' ? this.selectedLocationID : this.getUser.LocationID;
    }
  }
}
</script>