<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="400"
      max-width="350"
      offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn-toggle class="app-list-filter-date-btn-range" borderless>
        <v-text-field readonly
                      hide-details
                      :filled="filled"
                      :outlined="outlined"
                      :dense="dense"
                      :clearable="clearable"
                      v-on:click:clear="clear"
                      :value="getLabel"
                      :label="label"
                      v-bind="attrs"
                      v-on="on">
          <v-icon>event</v-icon>
        </v-text-field>
      </v-btn-toggle>
    </template>

    <v-card>
      <v-card-title>
        {{ singleDate ? 'Filtrează după dată' : 'Filtrează de la - până la' }}
      </v-card-title>
      <v-divider></v-divider>
      <v-date-picker @change="setRange" no-title full-width :first-day-of-week="0" v-model="range"
                     :range="!singleDate"/>
      <v-card-actions>
        <v-menu v-if="!singleDate" open-on-hover offset-y>
          <template v-slot:activator="{on}">
            <v-btn v-on="on" depressed>Interval</v-btn>
          </template>
          <v-list>
            <v-list-item-group @change="setDate">
              <v-list-item :value="item.value" selectable :key="item.text" v-for="item in intervals">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn
            text
            @click="menu = false">
          Închide
        </v-btn>
        <v-btn
            color="primary"
            text
            :disabled="!date1"
            @click="save">
          Aplică
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  data() {
    let val1 = null;
    let val2 = null;
    if (this.value) {
      val1 = this.value[0] || null;
      val2 = this.value[1] || null;
    }

    const currentMonth = [
      this.$moment().startOf('month').format('YYYY-MM-DD'),
      this.$moment().endOf('month').format('YYYY-MM-DD')
    ];

    const lastMonth = [
      this.$moment().subtract('1', 'months').startOf('month').format('YYYY-MM-DD'),
      this.$moment().subtract('1', 'months').endOf('month').format('YYYY-MM-DD'),
    ];

    const currentYear = [
      this.$moment().startOf('year').format('YYYY-MM-DD'),
      this.$moment().endOf('year').format('YYYY-MM-DD')
    ];

    return {
      range: [],
      date1: val1,
      date2: val2,
      menu: false,
      intervals: [
        {
          text: 'Azi',
          value: [
            this.$moment().format('YYYY-MM-DD'),
            this.$moment().format('YYYY-MM-DD')
          ]
        },
        {
          text: 'Ieri',
          value: [
            this.$moment().subtract('1', 'days').format('YYYY-MM-DD'),
            this.$moment().subtract('1', 'days').format('YYYY-MM-DD')
          ]
        },
        {
          text: 'Luna curentă',
          value: currentMonth
        },
        {
          text: 'Luna precedentă',
          value: lastMonth
        },
        {
          text: 'Anul curent',
          value: currentYear
        },
      ],
    }
  },


  watch: {
    value: function (value) {
      if (value[0]) {
        this.date1 = value[0];
      } else {
        this.range = [];
      }
      if (value[1]) {
        this.date2 = value[1];
      } else {
        this.range = [];
      }
    }
  },

  props: {
    label: String,
    value: Array,
    outlined: Boolean,
    clearable: Boolean,
    modal: Boolean,
    filled: Boolean,
    dense: Boolean,
    singleDate: Boolean,
  },

  methods: {
    setRange: function (values) {
      if (this.singleDate) {
        this.date1 = values;
        this.date2 = values;
      } else {
        this.date1 = values[0];
        this.date2 = values[1];
      }
    },

    setDate: function (values) {
      this.date1 = values[0];
      this.date2 = values[1];
      this.save();
    },

    save: function () {
      if (this.date1 && !this.date2) {
        this.date2 = this.date1;
      }
      if (this.singleDate) {
        this.$emit('input', [this.date1, this.date1]);
      } else {
        this.$emit('input', [this.date1, this.date2]);
      }
      this.menu = false;
    },

    clear: function () {
      this.date1 = null;
      this.date2 = null;
      this.$emit('input', []);
    },
  },

  computed: {
    getLabel: function () {
      let date1 = this.date1 ? this.$moment(this.date1).format('DD/MM/YYYY') : '';
      let date2 = this.date2 ? this.$moment(this.date2).format('DD/MM/YYYY') : '';
      if (this.date1 === this.date2) {
        return date1;
      }
      let line = date1 && date2 ? ' - ' : '';
      return `${date1}${line}${date2}`;
    },
  },
}
</script>
<style lang="scss" scoped>
.app-list-filter-date-btn-range {
  display: flex;
  flex-flow: column;

  input {
    margin: 0 !important;
  }

  .v-btn {
    border-radius: 4px !important;
  }
}
</style>