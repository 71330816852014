<template>
  <div>
    <v-app-bar dark class="app-bar" clipped-left app elevate-on-scroll>
      <TopBar/>
    </v-app-bar>
    <v-navigation-drawer app
                         width="300"
                         height="calc(100% - 110px)"
                         class="app-navigation"
                         clipped
                         permanent
                         :mini-variant.sync="mini">
      <Navigation :mini.sync="mini" :items="items"/>
    </v-navigation-drawer>
    <v-main>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </v-main>
  </div>
</template>
<script>
import Navigation from "@/components/app/menu/Navigation";
import TopBar from "@/components/app/bar/TopBar";

export default {

  components: {
    TopBar,
    Navigation,
  },

  data: () => ({
    mini: false,

    isAuthorizedVerification: null,

    items: [
      {title: 'Pagina principală', icon: 'grid_view', link: '/'},
      {title: 'Tranzacții', link: 'transactions'},

      {
        title: 'Nomenclator stații',
        icon: 'ballot',
        items: [
          {title: 'Stații', link: 'stations'},
          {title: 'Denumire joc', link: 'games'},
          {title: 'Cabinete', link: 'cabinets'},
          {title: 'Producători', link: 'vendors'},
          {title: 'Modele', link: 'models'},
        ]
      },

      {
        title: 'Nomenclator cheltuieli',
        icon: 'account_balance',
        items: [
          {title: 'Departamente', link: 'departments'},
          {title: 'Tip cheltuieli', link: 'expenditures'},
        ]
      },

      {
        title: 'Nomenclator inventar',
        icon: 'inventory_2',
        items: [
          {title: 'Inventar', link: 'inventory/inventories'},
          {title: 'Tipuri', link: 'inventory/types'},
          {title: 'Modele', link: 'inventory/models'},
        ]
      },

      {
        title: 'Nomenclator altele',
        icon: 'summarize',
        items: [
          {title: 'Locații', link: 'locations'},
          {title: 'Furnizori', link: 'inventory/providers'},
        ]
      },

      {
        title: 'Rapoarte',
        icon: 'tab',
        items: [
          {title: 'Locații', link: 'reports/locations'},
          {title: 'Stații', link: 'reports/stations'},
          {title: 'Cheltuieli', link: 'reports/expenditures'},
          {title: 'Tranzacții', link: 'transactions'},
          {title: 'Registru de Casă', link: 'reports/cash-register'},
          {title: 'Tranzacții - Registru de Casă', link: 'reports/cash-register-payments'},
          {title: 'Producători', link: 'reports/production'},
        ]
      },

      {title: 'Rapoarte simple', icon: 'assignment_return', link: 'simple-reports'},
    ]
  }),

  mounted() {
    this.isAuthorizedVerification = setInterval(() => {
      if (!this.$cookies.get('device_session')) {
        this.signOut();
      }
    }, 1500);
  },

  beforeUnmount() {
    window.clearInterval(this.isAuthorizedVerification);
  }
}
</script>
