<template>
  <div class="not-found-404">
    <h1>404 ?</h1>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.not-found-404 {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 60px;
}
</style>