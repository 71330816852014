<template>
  <app-list title="Modele"
            :search-by="['Name']"
            show-filters
            :query-fields="[
                'ID',
                'VendorID',
                'Name',
                'NrOfStations',
                'Vendor {Name}',
                'CreatedAt'
            ]"
            delete-mutation-type="deleteModel"
            delete-mutation-field="modelIDs"
            export-query-type="vendorModelsExport"
            :delete-by="getRole !== 'operations' ? 'ID' : null"
            query-type="vendorModels"
            add-link="models/add">
    <template #head>
      <lth sort-by="Name">Nume</lth>
      <lth>Producător</lth>
      <lth>NrOfStations</lth>
      <lth sort-by="CreatedAt">Data creării</lth>
    </template>
    <template #row="{item}">
      <ltd :link="`models/edit/${item.ID}`">{{ item.Name }}</ltd>
      <ltd :link="`vendors/edit/${item.VendorID}`">{{ item.Vendor ? item.Vendor.Name : '' }}</ltd>
      <ltd>{{ item.NrOfStations }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  created() {
    this.$store.commit('setListSort', {
      direction: 'asc',
      field: 'Name'
    });
  }

}
</script>