<template>
  <v-layout align-center>
    <v-progress-linear
        :active="getPreloader"
        :indeterminate="getPreloader"
        absolute
        bottom
        color="primary"
    />
    <v-layout align-center>
      <Logo/>
      <v-breadcrumbs class="app-breadcrumbs" large
                     :items="getBreadCrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              link
              :to="item.href"
              exact
              :disabled="item.disabled || !item.href">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-layout>
    <v-layout justify-end class="ml-auto mr-8">
      <v-btn :class="{'v-btn--active': getTheme, 'mr-1': true}" icon @click="setTheme">
        <v-icon>{{ getTheme ? 'nights_stay' : 'light_mode' }}</v-icon>
      </v-btn>
      <v-btn icon @click="logOut">
        <v-icon>logout</v-icon>
      </v-btn>
    </v-layout>
  </v-layout>
</template>
<script>
import Logo from '../../logo/Logo';

export default {
  components: {
    Logo
  },

  data() {
    return {
      search: null,
    }
  },

  mounted() {
    if (this.getTheme) {
      this.toggle.push(1);
    }
  },

  methods: {
    logOut: function () {
      let confirmation = confirm('Ești sigur?');
      if (confirmation) {
        this.signOut();
      }
    }
  },

  computed: {

  }

}
</script>